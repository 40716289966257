import { List, ListItem, ListItemText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/application.context";
import { AppService } from "../../../services/app.service";
import "./FormsList.css";
interface IProps{
    listData?: any[];
    handleSelectedItem?: (value: string) => void;
    selectedFormId?:string
}
const FormsList = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [formsList, setFormsList] = useState<any[]>([]);
    const [
        { data: getData, loading, error },
        fetchData
      ] = AppService.axiosHooks(
        {
          url: "forms",
          method: "GET"
        },
        { manual: true }
      );
    const loadForms = () => {
        fetchData().then((res) => {
          console.log('Forms list', res);
          if(res?.data && res.data.length > 0 ){
            setFormsList(res?.data || []);
          }
        }).catch((e: any) => {
          console.log(`Error in fetching forms list - ${e}`);
          setFormsList(appContext?.forms || []);
        })
      }
    useEffect(() => {
        loadForms();
    }, [])
    return (
        <>
        { formsList && formsList.length > 0 &&
            <List component="nav">
                {formsList.map((item: any) => {
                    return <ListItem className={item.id === props.selectedFormId ? 'selected' : ''} onClick={() => props?.handleSelectedItem?.(item.id)}>
                        <ListItemText primary={item.label || item.name} title={item.label || item.name}>
                        </ListItemText>
                    </ListItem>
                })}
            </List>
        }
        </>
    );
}
export default FormsList;