import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Paper, Box, Typography, Button, TextField } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AppContext from "../../../contexts/application.context";
import { AppService } from "../../../services/app.service";
import Alert from "../../molecules/Alert/Alert";

function Login() {
  const navigate = useNavigate();
  const [openAlert, setopenAlert] = useState(false);
  const [openAlertMsg, setopenAlertMsg] = useState("");
  const [openAlertStatus, setopenAlertStatus] = useState("");
  const [userNameError, setuserNameError] = useState(false);
  const [userPWDError, setuserPWDError] = useState(false);
  const context = useContext(AppContext);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var error = 0;
    if (data.get("email") === "") {
      setuserNameError(true);
      error++;
    }
    if (data.get("password") === "") {
      setuserPWDError(true);
      error++;
    }

    if (error === 0) {
      AppService.axios
        .post("auth/token", {
          username: data.get("email"),
          password: data.get("password"),
        })
        .then((res) => {
          if (res.status === 200) {
            context.isLoggedIn = true;
            localStorage.setItem("token", res.data.jwt);
            setopenAlert(true);
            setopenAlertStatus("success");
            setopenAlertMsg("Successfully Logged In");
            AutoCloseAlert();
            navigate("/clinicalApp/dashboard");
          } else {
            setopenAlert(true);
            setopenAlertStatus("error");
            setopenAlertMsg(res.data.Message);
          }
        })
        .catch((e) => {
          setopenAlert(true);
          setopenAlertStatus("error");
          setopenAlertMsg("Invalid Login");
          AutoCloseAlert();
          console.log(`Error in Login - ${e}`);
        });
    }
  };
  const handleCloseAlert = () => {
    setopenAlert(false);
    setopenAlertStatus("");
    setopenAlertMsg("");
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      setopenAlert(false);
      setopenAlertStatus("");
      setopenAlertMsg("");
    }, 3000);
  };
  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    var value = event.target.value;
    var name = event.target.name;
    if (name === "email") {
      if (value !== "") {
        setuserNameError(false);
      } else {
        setuserNameError(true);
      }
    } else if (name === "password") {
      if (value !== "") {
        setuserPWDError(false);
      } else {
        setuserPWDError(true);
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(/images/clinical-trials.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 20,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              label="User Name*"
              variant="outlined"
              name="email"
              sx={{ width: "100%" }}
              onChange={handleTextFieldChange}
              error={userNameError}
              helperText={userNameError === true && "This field is required"}
            />
            <TextField
              label="Password*"
              variant="outlined"
              name="password"
              type="password"
              sx={{ width: "100%", marginTop: "20px" }}
              onChange={handleTextFieldChange}
              error={userPWDError}
              helperText={userPWDError === true && "This field is required"}
            />

            {/* <FormInputText
              required={true}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Alert
              openAlertStatus={openAlertStatus}
              openAlert={openAlert}
              openAlertMsg={openAlertMsg}
              handleCloseAlert={handleCloseAlert}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
