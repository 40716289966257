import React from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { IOption } from "../../../models/Common";

interface IProps{
    id?: string;
    isDisabled?: boolean;
    value?: string;
    options?:IOption[]
}
const FormRadioGroup = (props: IProps) => {
    const handleChange = (e: any) => {
        console.log(e);
    }
    return (
        <RadioGroup row onChange={(event: any) => handleChange(event)} id={props.id}>
            {props.options && props.options.length > 0 && props.options.map((option: any) => (
                <FormControlLabel
                    value={option.value}
                    checked={props?.value === option.value ? true : false}
                    control={<Radio />}
                    label={option.label}
                    disabled={!props?.isDisabled}
                />
            ))}
        </RadioGroup>
    )
}
export default FormRadioGroup;