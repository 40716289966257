import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";
import FormContext, { sampleForm } from "../../../contexts/form.Context";
import { Box, Button, Container, Stack } from "@mui/material";
import {
  SaveOutlined
} from "@mui/icons-material";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { IClinicalForm } from "../../../models/ClinicalForm";
import AppContext from "../../../contexts/application.context";
window.jQuery = $;
window.$ = $;
require("jquery-ui-sortable");
require("formBuilder");

interface IFormProps{
  form?: IClinicalForm;
  handleFormData?: (formObj: any) => void;
  formFields?:any;
}
const FormBuilder = (props: IFormProps) => {
  const {form, handleFormData, formFields} = {...props};
  const navigate = useNavigate();
  const params = useParams();
  const appContext = useContext(AppContext);
  const [formTabDetails, setFormTabDetails] = useState(sampleForm.formTabs);
  const formObjRef = useRef<any>(null);
  const fb = useRef<HTMLDivElement>(null);
  let userAttrs: any = {};
  let formObj: any;
  const fields = [
    "checkbox-group",
    "date",
    "number",
    "radio-group",
    "select",
    "text",
    "textarea"
  ];
  const newAttributes = {
    units: {
      type: "select",
      label: "Units",
      values: ["Kg"],
    },
  };
  let formData:any = [];
  const saveForm = () => {
    const formFields = formObjRef.current?.actions?.getData();
    if(formFields && formFields.length > 0){
      console.log(JSON.stringify(formFields));
      handleFormData?.(formFields);
      //TODO - need to integrate create form API
      //navigate("/clinicalApp/forms/list");
    } else {
      console.log("Kindly select atleast one form field to create new form");
    }
  };
  const clearForm = () => {
    formObjRef.current?.actions.clearFields();
  }
  const renderForm = () => {
    fields.forEach(function (item, index) {
      userAttrs[item] = newAttributes;
    });
    // const formDetails = appContext.forms?.find((form) => {
    //   return form.id === params.formId;
    // });
    formData = formFields || {};
    try {
      //formData = JSON.parse(formData);
    } catch (error) {      
    }
    try {
      console.log("init", formData);
      const formOptions = {
        formData,
        showActionButtons: false,
        typeUserAttrs: userAttrs,
        disableFields: ["autocomplete", "file", "header", "hidden", "starRating", "button", "paragraph"]
      };
      if(!formObjRef.current){
        formObjRef.current = $(fb.current).formBuilder(formOptions);
      } else {
        console.log("formData");
        console.log(formData);
        console.log("formData");
        formObjRef.current?.actions?.setData?.(formData);
      }
    } catch (error) {
      console.log(`Error in preparing form builder - ${error}`);
    }
  }
  useEffect(() => {
    renderForm();
    console.log(formFields);
  },[params.formId])
  return (
    <>
      <FormContext.Provider value={formTabDetails}>
        <Container sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: "background.paper" }}>
          <Box pt={2}>
            <div id="fb-editor" ref={fb} />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            pt={4}
            pb={2}
          >
            <Button variant="outlined" startIcon={<GridDeleteIcon />} onClick={clearForm}>
              Clear
            </Button>
            <Button variant="contained" endIcon={<SaveOutlined />} onClick={saveForm} disabled={formObj?.actions?.getData?.().length === 0}>
              Save
            </Button>
          </Stack>
        </Container>
      </FormContext.Provider>
    </>
  );
};
export default FormBuilder;
