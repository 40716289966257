import React from "react";
import { MenuItem, Select } from "@mui/material";
import { IOption } from "../../../models/Common";

interface IProps{
    label?: string;
    isDisabled?: boolean;
    value?: string;
    options?:IOption[];
    id?: string | undefined | null;
}
const FormSelectBox = (props: IProps) => {
    const handleChange = (e: any) => {
        console.log(e);
    }
    return (
        <Select id={props.id || ""} value={props.value} style={{width: "100%"}} onChange={(event) => handleChange(event)} disabled={!props.isDisabled} label={props.label}>
              {props?.options && props?.options.length > 0 && props?.options.map((option: IOption) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
        </Select>
    )
}
export default FormSelectBox;