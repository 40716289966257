import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab } from "@mui/material";
export interface ITableListProps {
  headerlist: string[];
  handleSetActiveTab: (value: number) => void;
  selectedTab?: any;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TabList(props: ITableListProps) {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(props.selectedTab);
  }, [props.selectedTab]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    props.handleSetActiveTab(newValue);
  };
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {props.headerlist.map((item: any, index) => {
          return <Tab label={item} {...a11yProps(index)} />;
        })}
      </Tabs>
    </Box>
  );
}
export default TabList;
