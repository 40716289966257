import { Modal } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../../../contexts/application.context";
import { FORMSTATUS, IClinicalForm, IFormMetadata } from "../../../models/ClinicalForm";
import { AppService } from "../../../services/app.service";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import CreateForm from "../../organisms/CreateForm/CreateForm";
import QuickFilter from "../../organisms/QuickFIlter/QuickFilter";
import TableList from "../../organisms/TableList/TableList";
const FormsListPage = () => {
  const navigate = useNavigate();
  const {search} = useLocation();
  const appContext = useContext(AppContext);
  const [formsList, setFormsList] = useState<IClinicalForm[]>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false);
  const [
    { data: getData, loading, error },
    fetchData
  ] = AppService.axiosHooks(
    {
      url: "forms",
      method: "GET",
      params: {studyId: appContext.metaData?.studyId}
    },
    { manual: true }
  );
  const columns = [
    { field: "id", headerName: "Form Id", width: 250 },
    { field: "name", headerName: "Form Name", width: 450 },
    { field: "status", headerName: "Status", width: 250, renderCell: (params:GridRenderCellParams): React.ReactNode => {
      return <FormStatus status={params?.row?.status ?? FORMSTATUS.PENDING}/>
    } },
    { field: "version", headerName: "Form Version", width: 250 },
    { field: "createdAt", headerName: "Last updated", width: 250 },
  ];
  const loadForms = () => {
    fetchData().then((res: any) => {
      console.log('Forms list', res);
      if(res?.data && res.data.length > 0 ){
        setFormsList(res?.data || []);
      }
    }).catch((e: any) => {
      console.log(`Error in fetching forms list - ${e}`);
      setFormsList(appContext?.forms || []);
    })
  }
  const handleFormMetaData = (metaData: IFormMetadata) => {
    console.log(metaData);
    if(appContext){
      appContext.metaData = metaData;
    }
    setIsFormValid(true);
    loadForms();
  }
  const isFormReviewer = () => {
    const params = new URLSearchParams(search);
    return params?.get?.("isReviewer") === 'true';
  }
  const openCreateForm = () => {
    setIsCreateFormOpen(true);
  }
  const handleClose = () => {
    setIsCreateFormOpen(false);
  }
  useEffect(() => {
    //if(appContext.forms) setFormsList(appContext.forms);
    if(appContext.metaData){
      handleFormMetaData(appContext.metaData);
    }
    if(appContext.metaData && appContext.metaData.clientId && appContext.metaData.projectId && appContext.metaData.studyId){
      setIsFormValid(true);
    }
  }, [])
  return (
    <>
      <div>
        <QuickFilter handleFormMetadData={handleFormMetaData} metaData={appContext?.metaData}/>
        <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }}>
          <p>Forms list</p>
          {isFormValid && !isFormReviewer() && <p
            onClick={() => {
              // navigate("/clinicalApp/forms/builder/new");
              openCreateForm();
            }}
          >
            Create New Form
          </p>}
        </div>
        <div>
          <TableList
            columns={columns}
            data={formsList}
            rowClick={(event: any) => {
              if(isFormReviewer()){
                navigate(`/clinicalApp/forms/${event.id}/review`);
              } else {
                navigate(`/clinicalApp/forms/${event.id}`);
              }
            }}
          />
        </div>
      </div>
      <Modal open={isCreateFormOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={handleClose}>
        <CreateForm />          
      </Modal>
    </>
  );
};
export default FormsListPage;
