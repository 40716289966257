import DataEntryForm from "../../molecules/DataEntryForm/DataEntryForm";

function DataEntryReviewerForm() {
  return (
    <div>
      <DataEntryForm isDataEntryOperator={false} pageName={"Data Reviewer"} />
    </div>
  );
}
export default DataEntryReviewerForm;
