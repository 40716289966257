import React from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { IOption } from "../../../models/Common";

interface IProps{
    id?: string;
    isDisabled?: boolean;
    value?: string;
    options?:IOption[]
}
const FormCheckboxGroup = (props: IProps) => {
    console.log(props);
    const handleChange = (e: any) => {
        console.log(e);
    }
    return (
        <FormGroup row onChange={(event: any) => handleChange(event)} id={props.id}>
            {props.options && props.options.length > 0 && props.options.map((option: any) => (
                <FormControlLabel
                    value={option.value}
                    checked={props?.value === option.value ? true : false}
                    control={<Checkbox />}
                    label={option.label}
                    disabled={!props?.isDisabled}
                />
            ))}
        </FormGroup>
    )
}
export default FormCheckboxGroup;