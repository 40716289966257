import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ClearAllOutlined, Message, SaveOutlined } from "@mui/icons-material";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useNavigate } from "react-router-dom";
/* import EDCBreadcrumbs from "../BreadCrumbs"; */
import LinearProgress from "@mui/material/LinearProgress";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import axios from "axios";
import Comment from "../Comments/Comment";
import TabHeader from "../../organisms/TabHeader/TabHeader";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import ReviewCoverage from "../../organisms/ReviewCoverage/ReviewCoverage";

interface foremParam {
  isDataEntryOperator: boolean;
  pageName: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function commentProps(index: number) {
  return {
    id: `comment-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ClinicalForm(props: foremParam) {
  const [value, setValue] = useState(0);
  const breadCrumbList = [
    { title: "Home", link: "/clinicalApp/dashboard" },
    { title: props.pageName, link: "" },
  ];
  const navigate = useNavigate();
  const [popupForm, setPopupForm] = useState(false);
  const [formTitle, seFormTitle] = useState([{}]);
  const [formTabDetails, setFormDetails] = useState({
    formList: [
      {
        formelement: [{ value: "", units: "", reviewed: "" }],
        form_title: "",
        formlistid: "",
      },
    ],
    formid: "",
    formname: "",
    targetpercentage: 0,
    completedpercentage: 0,
  });

  React.useEffect(() => {
    loadDataEntry();
  }, []);

  const loadDataEntry = () => {
    axios.get("http://localhost:3000/assets/formdetails.json").then((res) => {
      setFormDetails(res.data);
      var formTitleList = new Array();
      res.data.formList.map((item: any) => {
        formTitleList.push(item.form_title);
      });
      seFormTitle(formTitleList);
    });
  };
  const addPopupcloseHandler = () => {
    setOpen(false);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    frmIndex: number,
    frmelementIndex: number
  ) => {
    updateFormData(event.target.value, frmIndex, frmelementIndex, "");
  };
  const handleSelectFieldChange = (
    event: SelectChangeEvent<string>,
    frmIndex: number,
    frmelementIndex: number,
    type: string
  ) => {
    updateFormData(event.target.value, frmIndex, frmelementIndex, type);
  };

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    frmIndex: number,
    frmelementIndex: number
  ) => {
    updateFormData(event.target.value, frmIndex, frmelementIndex, "");
  };

  const markAsRead = (frmIndex: number, frmelementIndex: number) => {
    updateFormData("Yes", frmIndex, frmelementIndex, "reviewed");
  };

  const updateFormData = (
    changeValue: string,
    frmIndex: number,
    frmelementIndex: number,
    type: any
  ) => {
    var updateformList = new Array();
    formTabDetails.formList.map((item, index) => {
      if (frmIndex === index) {
        var updateformelement = new Array();
        item.formelement.map((frmItem, frmIndex) => {
          if (frmIndex === frmelementIndex) {
            var frmItems = frmItem;

            if (type === "unit") {
              frmItems["units"] = changeValue;
            } else if (type === "reviewed") {
              frmItems["reviewed"] = changeValue;
            } else {
              frmItems["value"] = changeValue;
            }

            updateformelement.push(frmItems);
          } else {
            updateformelement.push(frmItem);
          }
        });
        updateformList.push({
          form_title: item.form_title,
          formlistid: item.formlistid,
          formelement: updateformelement,
        });
      } else {
        updateformList.push(item);
      }
    });
    var newFormTabDetails = {
      formid: formTabDetails.formid,
      formname: formTabDetails.formname,
      formList: updateformList,
      targetpercentage: formTabDetails.targetpercentage,
      completedpercentage: formTabDetails.completedpercentage,
    };
    setFormDetails(newFormTabDetails);
  };

  const year = new Date().getFullYear();
  const renderFormObj = (formItem: any, index: any) => {
    const formData = formItem.formelement;
    const filedMapper = [
      {
        type: "header",
        value: (element: any, elementIndex: any) => (
          <Box>
            <Typography component="h1" variant="h5" gutterBottom>
              {element.label}
            </Typography>
          </Box>
        ),
      },
      {
        type: "paragraph",
        value: (element: any) => (
          <Box sx={{ width: "100%", maxWidth: 800 }}>
            <Typography variant="subtitle1" gutterBottom>
              {element.label}
            </Typography>
          </Box>
        ),
      },
      {
        type: "text",
        value: (element: any, elementIndex: any) => (
          <Grid item xs={props.isDataEntryOperator === true ? 8 : 6}>
            <Input
              style={{
                width:
                  element.units && element.units.length !== 0 ? "70%" : "100%",
              }}
              type="text"
              value={element.value ? element.value : ""}
              onChange={(event) =>
                handleTextFieldChange(event, index, elementIndex)
              }
              disabled={!props.isDataEntryOperator}
            />
            {element.units && element.units.length !== 0 && (
              <Select
                value={element.units}
                style={{
                  width: "28%",
                  marginLeft: "10px",
                }}
                onChange={(event) =>
                  handleSelectFieldChange(event, index, elementIndex, "unit")
                }
              >
                {element.options.map((option: any) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        ),
      },
      {
        type: "number",
        value: (element: any, elementIndex: any) => (
          <Grid item xs={props.isDataEntryOperator === true ? 8 : 6}>
            <Input
              type="number"
              onChange={(event) =>
                handleTextFieldChange(event, index, elementIndex)
              }
              value={element.value ? element.value : ""}
              disabled={!props.isDataEntryOperator}
            />
          </Grid>
        ),
      },
      {
        type: "select",
        value: (element: any, elementIndex: any) => (
          <Grid item xs={props.isDataEntryOperator === true ? 8 : 6}>
            <Select
              value={element.value}
              style={{
                width: "100%",
              }}
              onChange={(event) =>
                handleSelectFieldChange(event, index, elementIndex, "")
              }
              disabled={!props.isDataEntryOperator}
            >
              {element.options.map((option: any) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
            </Select>
          </Grid>
        ),
      },
      {
        type: "radio-group",
        value: (element: any, elementIndex: any) => (
          <Grid item xs={props.isDataEntryOperator === true ? 8 : 6}>
            {element.selectedvalue}
            <RadioGroup
              row
              onChange={(event) =>
                handleRadioChange(event, index, elementIndex)
              }
            >
              {element.options.map((option: any) => (
                <FormControlLabel
                  value={option.value}
                  checked={element.value === option.value ? true : false}
                  control={<Radio />}
                  label={option.label}
                  disabled={!props.isDataEntryOperator}
                />
              ))}
            </RadioGroup>
          </Grid>
        ),
      },
      {
        type: "date",
        value: (element: any, elementIndex: any) => (
          <Grid item xs={props.isDataEntryOperator === true ? 8 : 6}>
            <Select
              label="Day"
              value={element.value !== "" ? element.value.substring(10, 8) : ""}
              sx={{
                width: { xs: "100px", sm: "100px" },
              }}
              onChange={(event) =>
                handleSelectFieldChange(event, index, elementIndex, "date")
              }
              disabled={!props.isDataEntryOperator}
            >
              <MenuItem value="">
                <em>Select Date</em>
              </MenuItem>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
            <Select
              id="input-with-icon-adornment"
              label="Month"
              value={element.value !== "" ? element.value.substring(7, 5) : ""}
              sx={{
                width: { xs: "100px", sm: "100px" },
              }}
              onChange={(event) =>
                handleSelectFieldChange(event, index, elementIndex, "month")
              }
              disabled={!props.isDataEntryOperator}
            >
              <MenuItem value="">
                <em>Select Month</em>
              </MenuItem>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((option) => (
                <MenuItem value={minTwoDigits(option)}>{option}</MenuItem>
              ))}
            </Select>

            <Select
              label="year"
              value={element.value !== "" ? element.value.substring(0, 4) : ""}
              sx={{
                width: { xs: "100px", sm: "100px" },
              }}
              onChange={(event) =>
                handleSelectFieldChange(event, index, elementIndex, "year")
              }
              disabled={!props.isDataEntryOperator}
            >
              <MenuItem value="">
                <em>Select Year</em>
              </MenuItem>
              {Array.from(new Array(60), (val, index) => year - index).map(
                (option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                )
              )}
            </Select>
          </Grid>
        ),
      },
    ];
    return formData.map((element: any, subindex: any) => {
      const isFoundIndex = filedMapper.findIndex(
        (field) => field.type === element.type
      );
      if (isFoundIndex !== -1) {
        return (
          <Grid item xs={12} sm={12} spacing={2} m={2} pt={2} mb={0}>
            {element.type === "paragraph" || element.type === "header" ? (
              filedMapper[isFoundIndex]["value"](element, subindex)
            ) : (
              <FormControl variant="standard" fullWidth>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <InputAdornment position="start">
                      <Message onClick={handleOpen} />
                    </InputAdornment>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {element.label}
                    </Typography>
                  </Grid>
                  {filedMapper[isFoundIndex]["value"](element, subindex)}
                  {element.reviewed === "Yes" ? (
                    <Grid item xs={props.isDataEntryOperator === true ? 1 : 3}>
                      <Typography
                        variant="caption"
                        gutterBottom
                        style={{ color: "#4caf50", fontWeight: "bold" }}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        REVIWED
                      </Typography>
                    </Grid>
                  ) : (
                    props.isDataEntryOperator === false && (
                      <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-end">
                          <Button
                            variant="contained"
                            onClick={(event) => markAsRead(index, subindex)}
                          >
                            Mark As Reviewd
                          </Button>
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </FormControl>
            )}
          </Grid>
        );
      }
    });
  };

  function minTwoDigits(n: number) {
    return (n < 10 ? "0" : "") + n;
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const saveForm = () => {
    console.log(formTabDetails, "formTabDetails");
  };
  const handleSetActiveTab = (value: number) => {
    setValue(value);
  };

  const loadTab = () => {
    if (formTabDetails.formList.length > 0) {
      var tabHeader = new Array();
      formTabDetails.formList.map((item: any) => {
        tabHeader.push(item.form_title);
      });

      return (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabHeader
            headerlist={tabHeader}
            handleSetActiveTab={handleSetActiveTab}
          />
        </Box>
      );
    }
  };

  return (
    <>
      <Breadcrumb breadCrumbList={breadCrumbList} />
      <Grid container spacing={2}>
        <Grid item xs={props.isDataEntryOperator === false ? 9 : 12}>
          <Box
            sx={{
              maxWidth: { xs: "100%", sm: "100%" },
              bgcolor: "background.paper",
            }}
            style={{}}
          >
            {loadTab()}
            <Box>
              {renderFormObj(formTabDetails.formList[value], value)}
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                spacing={2}
                pt={4}
                pb={2}
              >
                <Link
                  href={
                    props.isDataEntryOperator === true
                      ? "/clinicalApp/dataentry"
                      : "/clinicalApp/dataEntryReviewer"
                  }
                >
                  <Button variant="outlined" endIcon={<ClearAllOutlined />}>
                    Cancel
                  </Button>
                </Link>
                {props.isDataEntryOperator === true && (
                  <Button variant="outlined" endIcon={<DraftsIcon />}>
                    Save As Draft
                  </Button>
                )}
                {props.isDataEntryOperator === false && (
                  <Button variant="outlined" endIcon={<DoDisturbOffIcon />}>
                    Reject
                  </Button>
                )}
                <Button
                  variant="contained"
                  endIcon={<SaveOutlined />}
                  onClick={saveForm}
                >
                  {props.isDataEntryOperator === true ? "Submit" : "Approve"}
                </Button>
              </Stack>
            </Box>
          </Box>
        </Grid>
        {props.isDataEntryOperator === false && (
          <ReviewCoverage
            targetPercentage={formTabDetails.targetpercentage}
            completedpercentage={formTabDetails.completedpercentage}
          />
        )}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Comment
          isDataEntryOperator={props.isDataEntryOperator}
          addPopupcloseHandler={addPopupcloseHandler}
        />
      </Modal>
    </>
  );
}
export default ClinicalForm;
