import React from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  React.useEffect(() => {
    localStorage.removeItem("token");
    navigate("/");
  }, []);

  return <div>logging out...</div>;
}

export default Logout;
