import React from "react";
import { IClinicalForm } from "../models/ClinicalForm";

declare global {
  interface Window {
    jQuery: any;
    $: any;
  }
}
export const getFormDetails = (forms: IClinicalForm[], formId: string) => {
  let formData = null;
  if(forms && forms.length > 0){
    formData = forms.find((form:IClinicalForm) => {
      return form.id === formId;
    });
  }
  return formData;
}
