import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { FORMELEMENTSTATUS} from "../../../models/Common";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import "./FormViewerFieldStatus.css";
interface IProps{
    currentStatus: FORMELEMENTSTATUS | undefined;
    handleStatusChange: (status: FORMELEMENTSTATUS) => void;
}
const FormViewerFieldStatus = (props: IProps) => {
    const updateFormFieldStatus = (status: FORMELEMENTSTATUS) => {
        props?.handleStatusChange(status);
    }
    return (
        <Box sx={{width: "10%", display: "flex", justifyContent:"space-evenly", padding: "10px",  alignItems:"center"}} data-selected-status={props.currentStatus} className="statusIcon">
            <Box title="Approve" sx={{cursor: "pointer"}} onClick={() => updateFormFieldStatus(FORMELEMENTSTATUS.APPROVED)}><CheckCircleOutlineOutlinedIcon className={`approved ${props.currentStatus === FORMELEMENTSTATUS.APPROVED  ? 'active' : ''}`}/></Box>
            <Box title="Reject" sx={{cursor: "pointer"}} onClick={() => updateFormFieldStatus(FORMELEMENTSTATUS.REJECTED)}><CancelOutlinedIcon className={`rejected ${props.currentStatus === FORMELEMENTSTATUS.REJECTED  ? 'active' : ''}`}/></Box>
        </Box>
    )
}

export default FormViewerFieldStatus;