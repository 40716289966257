import React, { createContext, useContext, useMemo } from "react";
import { AutocompleteOption } from "../components/molecules/AutoComplete/AutoComplete";
import { IClinicalForm, IFormMetadata } from "../models/ClinicalForm";
import { IUser } from "../models/User";
export interface IFilerOption {
  projectsList: AutocompleteOption[];
  studyList: AutocompleteOption[];
}
export interface IAppContext {
  isLoggedIn: boolean;
  user?: IUser;
  forms?: IClinicalForm[];
  metaData?: IFormMetadata; //TODO - temp added the metadata here for initial demo
  optionList?: IFilerOption;
}
const defContext: IAppContext = {
  isLoggedIn: false,
  user: { id: "", name: "", type: "" } as IUser,
  forms: [],
  optionList: {
    projectsList: [],
    studyList: [],
  },
};
const AppContext = React.createContext<IAppContext>(defContext);
export const AppProvider = ({ children }: any) => {
  return (
    <AppContext.Provider value={defContext}>{children}</AppContext.Provider>
  );
};
export default AppContext;
