import React, { useState } from "react";
/* import $ from 'jquery'; */
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  Menu,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import axios from "axios";
import DataEntryComments from "../../organisms/DataEntryComments/DataEntryComments";
import { IReviewerComments, monthName } from "../../../models/Common";

interface foremParam {
  isDataEntryOperator: boolean;
  addPopupcloseHandler: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function Comment(props: foremParam) {
  const [commentlist, setCommentList] = useState<IReviewerComments[]>([]);

  React.useEffect(() => {
    loadComments();
    /* loadReviewerComments(); */
  }, []);

  const loadComments = () => {
    axios.get("http://localhost:3000/assets/comments.json").then((res) => {
      setCommentList(res.data);
    });
  };

  const saveComments = (reviewerIndex: number) => {
    var newCommentList = new Array();
    var todayDate = new Date();
    commentlist.map((item, index) => {
      var name,
        type = "";
      if (props.isDataEntryOperator === true) {
        name = "Builder";
        type = "builder";
      } else {
        name = "Reviewer";
        type = "reviewer";
      }

      var existComments = item.comments;
      if (reviewerIndex === index) {
        existComments.push({
          text: commentText,
          timestamp:
            todayDate.getDate() +
            " " +
            monthName[todayDate.getMonth()] +
            " " +
            todayDate.getFullYear() +
            ", " +
            todayDate.getHours() +
            ":" +
            todayDate.getMinutes(),
          user: { id: "1", name: name, type: type },
          status: "pending",
        });

        newCommentList.push({
          reviewer: item.reviewer,
          comments: existComments,
        });
      } else {
        newCommentList.push(item);
      }
    });
    setCommentText("");
    setCommentList(newCommentList);
  };

  const [commentText, setCommentText] = useState("");

  return (
    <Box
      sx={{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1000,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Comments
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <DataEntryComments
        isDataEntryOperator={props.isDataEntryOperator}
        reviewercommentlist={commentlist}
        addPopupcloseHandler={props.addPopupcloseHandler}
        saveComments={saveComments}
        setCommentText={setCommentText}
        commentText={commentText}
      />
    </Box>
  );
}
export default Comment;
