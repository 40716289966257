import React, { useState } from "react";
import { Box } from "@mui/system";
import { IReviewerComments} from "../../../models/Common";
import { AppBar, Button, FormControl, Input, InputLabel, Stack, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import Comments from "../Comments/Comments";
interface IProps{
    reviewerComments: IReviewerComments[];
    saveComments: (obj: any) => void;
    handleClose: () => void;
}
const ReviewerComments = (props: IProps) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [commentText, setCommentText] = useState<string>("");
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }
    return (
        <Box sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
          }}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Comments
                    </Typography>
                </Toolbar>
                </AppBar>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs scrollButtons="auto" aria-label="scrollable auto tabs example" value={selectedTabIndex}>
                {props?.reviewerComments?.length > 0 && props.reviewerComments.map((item: IReviewerComments, index:number) => {
                    return <Tab label={item.reviewer.name} {...a11yProps(index)} onClick={() => setSelectedTabIndex(index)} />;
                })}
                </Tabs>
            </Box>
            <Box>
                <div role="tabpanel" style={{padding:"20px 0"}}>
                    <Box sx={{mb: 2,display: "flex",flexDirection: "column",height: 250,overflow: "hidden",overflowY: "scroll"}}>
                        <Comments comments={props.reviewerComments?.[selectedTabIndex]?.comments || []}/>
                    </Box>
                </div>
            </Box>
            <Box sx={{p: 3}}>
                <Box sx={{ "& > :not(style)": { m: 1 } }}>
                    <FormControl fullWidth variant="standard">
                        <InputLabel htmlFor="input-with-icon-adornment">Comments</InputLabel>
                        <Input style={{width: "100%",}} value={commentText} onChange={(event) => setCommentText(event.target.value)}/>
                    </FormControl>
                </Box>
                <Stack direction="row" alignItems="right" justifyContent="right" spacing={2} pt={4} pb={2}>
                    <Button variant="outlined" onClick={() => props.handleClose?.()}>Cancel</Button>
                    <Button variant="contained" onClick={(event) => {props.saveComments?.({value: commentText, reviewerIndex: selectedTabIndex}); setCommentText("")}}>Reply</Button>
                </Stack>
            </Box>
        </Box>
    )
}

export default ReviewerComments;