import React, { useContext, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid";
import AutoComplete, { AutocompleteOption } from "../../molecules/AutoComplete/AutoComplete";
import { AppService } from "../../../services/app.service";
import { IFormMetadata } from "../../../models/ClinicalForm";
import AppContext from "../../../contexts/application.context";
import "./QuickFilter.css";
interface IProps{
    handleFormMetadData?: (data: IFormMetadata) => void
    metaData?:IFormMetadata;
}
const QuickFilter = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [projectsList, setProjectsList] = useState<AutocompleteOption[]>(appContext?.optionList?.projectsList || []);
    const [studyList, setStudyList] = useState<AutocompleteOption[]>(appContext?.optionList?.studyList || []);
    const [filterObj, setFilterObj] = useState<IFormMetadata>({clientId: "", projectId: "", studyId: ""});
    const [
        { data: getData, loading, error },
        fetchData
      ] = AppService.axiosHooks(
        {
          url: "getProjects",
          method: "GET"
        },
        { manual: true }
      );
    
    const handleClientSelection = (selectedValue: AutocompleteOption) => {
        if(selectedValue){
            setFilterObj({clientId: selectedValue.id, projectId: "", studyId: "'"});
            setStudyList([]);
            if (appContext && appContext.optionList){
                appContext.optionList.studyList = [];
            }
            fetchData().then((res) => {
                console.log(res);
            }).catch((e) => {
                const data = [
                    {id: "1", label: "Project 1"},
                    {id: "2", label: "Project 2"}
                ];
                setProjectsList(data);
                if (appContext && appContext.optionList){
                    appContext.optionList.projectsList = data;
                }
            })
        }
    }
    const handleProjectSelection = (selectedValue: AutocompleteOption) => {
        if(selectedValue){
            setFilterObj({...filterObj, projectId: selectedValue.id});
            const data = [
                {id: "1", label: "Study 1 - 1"},
                {id: "2", label: "Study 1 - 2"}
            ]
            setStudyList(data);
            if (appContext && appContext.optionList){
                appContext.optionList.studyList = data;
            }
        }
    }
    const handleStudySelection = (selectedValue: AutocompleteOption) => {
        if(selectedValue){
            setFilterObj({...filterObj, studyId: selectedValue.id});
            filterObj.studyId = selectedValue.id;
        }
    }
    const handleFormClick = () =>{
        if(filterObj.clientId && filterObj.projectId && filterObj.studyId){
            props?.handleFormMetadData?.(filterObj);
        } else {
            console.log("Need to select Client / project / Study before filtering");
        }
    }
    return <>
        <h3>Quick Filter</h3>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <AutoComplete label="Client Name" options={[{id: "1", label: "Client 1"}]} onSelect={handleClientSelection} selectedId={props.metaData?.clientId}/>
            </Grid>
            <Grid item xs={12} sm={3}>
                <AutoComplete label="Project Name" options={projectsList} onSelect={handleProjectSelection} selectedId={props.metaData?.projectId}/>
            </Grid>
            <Grid item xs={12} sm={3}>
                <AutoComplete label="Study Name" options={studyList} onSelect={handleStudySelection} selectedId={props.metaData?.studyId}/>
            </Grid>
        </Grid>   
        <Grid m={1} pt={2} container spacing={2} direction="row"
                alignItems="center"
                justifyContent="center">
                <Box mr={1}>
                    <Button size="small"  color="primary" variant="contained" onClick={handleFormClick}>
                        <GridSearchIcon fontSize="small" /> Search
                    </Button>
                </Box>
            </Grid>  
    </>
}
export default QuickFilter;