import { Paper, Typography, Breadcrumbs, Link, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Breadcrumb.css";
export interface IBreadCrumb {
  title: string;
  link?: string;
  navigateTo?: string;
}
export interface IBreadCrumbProps {
  breadCrumbList: IBreadCrumb[];
}
const Breadcrumb = (props: IBreadCrumbProps) => {
  const navigate = useNavigate();
  const handleNavigate = (e: any, item: IBreadCrumb) => {
    e.preventDefault();
    item && item.navigateTo && navigate(item?.navigateTo);
  };
  return (
    <Box sx={{ width: 1, mb: 2 }}>
      <Paper square sx={{ p: 2 }}>
        <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
          {props.breadCrumbList.map((item, index) => {
            if (item.navigateTo) {
              return (
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={(e: any) => handleNavigate(e, item)}
                  href={item.navigateTo}
                  key={index}
                >
                  {item.title}
                </Link>
              );
            } else if (item.link) {
              return (
                <Link
                  underline="hover"
                  color="inherit"
                  href={item.link}
                  key={index}
                >
                  {item.title}
                </Link>
              );
            } else {
              return (
                <Typography color="text.primary" key={index}>
                  {item.title}
                </Typography>
              );
            }
          })}
        </Breadcrumbs>
      </Paper>
    </Box>
  );
};
export default Breadcrumb;
