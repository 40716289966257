import { Box, Grid, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

interface foremParam {
  targetPercentage: number;
  completedpercentage: number;
}

function ReviewCoverage(props: foremParam) {
  return (
    <Grid item xs={3}>
      <Box
        sx={{
          maxWidth: { xs: "100%", sm: "100%" },
          bgcolor: "background.paper",
        }}
        p={2}
      >
        <Typography variant="h5" gutterBottom>
          Review Coverage
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          Target
          <br />
          {props.targetPercentage}%
        </Typography>
        <LinearProgress
          variant="determinate"
          value={props.completedpercentage}
        />{" "}
        {props.completedpercentage}%
      </Box>
    </Grid>
  );
}
export default ReviewCoverage;
