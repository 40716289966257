import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

type Props = {
  openAlert: boolean;
  openAlertMsg: string;
  openAlertStatus: string;
  handleCloseAlert: () => void;
};
const AlertMessage = (props: Props) => {
  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      {props.openAlertStatus === "success" && (
        <Snackbar
          open={props.openAlert}
          autoHideDuration={6000}
          onClose={props.handleCloseAlert}
        >
          <Alert
            onClose={props.handleCloseAlert}
            severity="success"
            sx={{ width: "100%" }}
          >
            {props.openAlertMsg}
          </Alert>
        </Snackbar>
      )}
      {props.openAlertStatus === "error" && (
        <Snackbar
          open={props.openAlert}
          autoHideDuration={6000}
          onClose={props.handleCloseAlert}
        >
          <Alert
            onClose={props.handleCloseAlert}
            severity="error"
            sx={{ width: "100%" }}
          >
            {props.openAlertMsg}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default AlertMessage;
