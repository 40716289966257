import { Box, Button, Modal, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Link from "@mui/material/Link";
import { useNavigate, useParams } from "react-router-dom";
import { getFormDetails } from "../../../common/util";
import AppContext from "../../../contexts/application.context";
import { FORMELEMENTSTATUS, IFormElement, IReviewerComments, monthName } from "../../../models/Common";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import FormsList from "../../organisms/FormsList/FormsList";
import FormViewer from "../../organisms/FormViewer/FormViewer";
import ReviewCoverage from "../../organisms/ReviewCoverage/ReviewCoverage";
import { ClearAllOutlined, Message, SaveOutlined } from "@mui/icons-material";
import DoDisturbOffIcon from "@mui/icons-material/DoDisturbOff";
import { FORMSTATUS, IClinicalForm } from "../../../models/ClinicalForm";
import ReviewerComments from "../../organisms/ReviewerComments/ReviewerComments";
import { demoComments } from "../CreateClinicalForm/CreateClinicalForm";
interface IFormPercentage{
    total: number;
    completed: number;
}
const FormReviewerPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const [formPercentage, setFormPercentage] = useState<IFormPercentage>({total: 100, completed: 0});
    const [fieldReviewerComments, setFieldReviewerComments] = useState<IReviewerComments[]>(demoComments);
    const [isReviewCommentsOpen, setIsReviewCommentsOpen] = useState<boolean>(false);
    const [selectedFieldName, setSelectedFieldName] = useState<string>("");
    const handleSelectedForm = (formId: string) => {
        formId && navigate(`/clinicalApp/forms/${formId}/review`);
    }
    const retrieveFormData = (forms: IClinicalForm[]) => {
        let formData = null;
        if(params.formId && forms && forms.length > 0){
            formData = getFormDetails(forms, params.formId);
            if(formData && formData?.data && typeof(formData.data) === "string"){
                formData.data = JSON.parse(formData?.data);
            }
        }
        return formData;
    }
    const updateReviewerFieldProgress = () => {
        setFormPercentage({...formPercentage, completed: 0})
        const formData = retrieveFormData(appContext.forms || []);
        if(formData){
            const totalFields = formData?.data.length;
            const reviewedFields = formData?.data.filter((item: IFormElement) => (item.status === FORMELEMENTSTATUS.APPROVED || item.status === FORMELEMENTSTATUS.REJECTED))?.length || 0;
            if(totalFields && reviewedFields){
                const totalReviewed = Math.floor(reviewedFields / totalFields * 100);
                setFormPercentage({...formPercentage, completed: totalReviewed})
            }
        }
    }
    const updateFormStatus = (status: FORMSTATUS) => {
        const existingForms = [...appContext.forms || []];
        const formData = retrieveFormData(existingForms);
        if(formData){
            formData.status = status;
            appContext.forms = existingForms;
        }
        navigate("/clinicalApp/forms/list?isReviewer=true");
    }
    const openFieldReviewComments = (fieldName: string) => {
        setSelectedFieldName(fieldName);
        console.log("fieldName", fieldName);
        const existingForms = [...appContext.forms || []];
        const formData = retrieveFormData(existingForms);
        setIsReviewCommentsOpen(false);
        if(fieldName){
            setIsReviewCommentsOpen(true);
            if(formData){
                const fieldData = formData.data.find((ele: IFormElement) => ele.name === fieldName);
                console.log(fieldData);
                if(fieldData && fieldData.reviewComments && fieldData.reviewComments.length > 0){
                    setFieldReviewerComments([...fieldData.reviewComments]);
                } else {
                    setFieldReviewerComments([...demoComments]);
                }
            }
        }
    }
    const handleSaveComments = (obj: any) => {
        const reviewerIndex = obj?.reviewerIndex;
        const commentsText = obj?.value;
        const updatedReviewerComments = [...fieldReviewerComments];
        const reviewerData = updatedReviewerComments[reviewerIndex];
        if(reviewerData){
            const currDate = new Date();
            reviewerData.comments.push({text: commentsText, timestamp: `${currDate.getDate()} ${monthName[currDate.getMonth()+1]} ${currDate.getFullYear()}, ${currDate.getHours()}:${currDate.getMinutes()}`, user: {id: "temp", name: "Form Builder", type: "builder"}, status: ""});
        }
        setFieldReviewerComments(updatedReviewerComments);
        
        const existingForms = [...appContext.forms || []];
        const formData = retrieveFormData(existingForms);
        console.log("selectedFieldName",selectedFieldName);
        if(selectedFieldName){
            if(formData){
                const fieldData = formData.data.find((ele: IFormElement) => ele.name === selectedFieldName);
                console.log("fieldData", fieldData);
                console.log(updatedReviewerComments);
                if(fieldData){
                    if(fieldData.reviewComments && fieldData.reviewComments.length > 0){
                        fieldData.reviewComments = updatedReviewerComments;
                    } else {
                        fieldData.reviewComments = demoComments;
                    }
                }
            }
        }
    }
    const handleClose = () => {
        setIsReviewCommentsOpen(false);
    }
    useEffect(() => {
        updateReviewerFieldProgress();
    },[params?.formId])
    return (
        <>
            <Breadcrumb breadCrumbList={[{title: "Home", navigateTo: "/clinicalApp/forms/list?isReviewer=true"}, {title: "Client 1"},{title: "Project 1"},{title: "Study 1"}]} />
            <Box sx={{display: "flex", height: '100%'}} className="form-viewer">
                <Box sx={{width: "200px", maxWidth: "200px", overflowX:"auto", border: "1px #ccc solid", height: '100%'}}>
                    <FormsList selectedFormId={params.formId} handleSelectedItem={handleSelectedForm}/>
                </Box>
                <Box sx={{display: "flex", width: "100%", height: '100%', padding: 0, margin: 0}}>
                    <Box sx={{width: "85%"}}>
                        <FormViewer formId={params.formId || ""} handleStatusChange={updateReviewerFieldProgress} openFieldReviewComments={openFieldReviewComments}/>
                        <Stack direction="row" alignItems="right" justifyContent="right" spacing={2} pt={4} pb={2}>
                            <Button variant="outlined" endIcon={<ClearAllOutlined />} onClick={() => navigate("/clinicalApp/forms/list?isReviewer=true")}>
                                Cancel
                            </Button>
                            <Button variant="outlined" endIcon={<DoDisturbOffIcon />} onClick={() => updateFormStatus(FORMSTATUS.REJECTED)}>
                                Reject
                            </Button>
                            <Button variant="contained" endIcon={<SaveOutlined />} disabled={formPercentage.completed === 100 ? false : true} onClick={() => updateFormStatus(FORMSTATUS.APPROVED)}>
                                Approve
                            </Button>
                        </Stack>
                    </Box>
                    <Box sx={{width: "15%"}}>
                        <ReviewCoverage
                            targetPercentage={formPercentage.total}
                            completedpercentage={formPercentage.completed}
                        />
                    </Box>
                </Box>
            </Box>
            <Modal open={isReviewCommentsOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" onClose={handleClose}>
                <ReviewerComments reviewerComments={fieldReviewerComments} saveComments={handleSaveComments} handleClose={handleClose}/>
            </Modal>
        </>
    )
}
export default FormReviewerPage;