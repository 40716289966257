import React, { SyntheticEvent, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material"
export interface AutocompleteOption {
    label: string;
    id: string;
}
interface IProps{
    options: AutocompleteOption[];
    label: string;
    selectedId?:string;
    selectedValue?: AutocompleteOption;
    onSelect?: (value: AutocompleteOption) => void;
}
const AutoComplete = (props: IProps) => {
    const [selectedValue, setSelectedValue] = useState<AutocompleteOption>();
    useEffect(() => {
        if(props.options && props.options.length > 0){
            const selectedOption = props.options.find((option: AutocompleteOption) => {
                return option.id === props.selectedId;
            })
            selectedOption && setSelectedValue(selectedOption);
            console.log("selectedOption", selectedOption);
        }
        console.log("props.selectedId", props.selectedId);
    }, [props.selectedId])
    return <Autocomplete
        id="disable-close-on-select"
        inputValue={selectedValue?.label || ""}
        autoHighlight
        options={props.options}
        onChange={(event:SyntheticEvent, value: any) => {
            console.log(value);
            setSelectedValue(value);
            props?.onSelect?.(value);
        }}
        renderInput={(params) => (
            <TextField {...params} label={props.label} variant="standard" sx={{
                '& .MuiFormLabel-root': {
                    fontSize: '1rem',
                },
            }} />
        )}
    />
}
export default AutoComplete;