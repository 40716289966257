import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import GradingIcon from "@mui/icons-material/Grading";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AllOutIcon from "@mui/icons-material/AllOut";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import LogoutIcon from "@mui/icons-material/Logout";

import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AppContext from "../contexts/application.context";

export default function ListItems() {
  const context = useContext(AppContext);
  var userType = context.user;
  const location = useLocation();
  var pathname = location.pathname;
  var onboarOpen = false;
  if (pathname.indexOf("onboarding") >= 0) {
    onboarOpen = true;
  }
  const [open, setOpen] = React.useState(onboarOpen);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Link to="dashboard" title="Dashboard">
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>
        <Link to="forms/list" title="Form Builder">
          <ListItemButton>
            <ListItemIcon>
              <PlaylistAddIcon />
            </ListItemIcon>
            <ListItemText primary="Form Builder" />
          </ListItemButton>
        </Link>

        <Link to="forms/list?isReviewer=true" title="Form Reviewer">
          <ListItemButton>
            <ListItemIcon>
              <GradingIcon />
            </ListItemIcon>
            <ListItemText primary="Form Reviewer" />
          </ListItemButton>
        </Link>
        <Link to="querymanagement" title="Query Management">
          <ListItemButton>
            <ListItemIcon>
              <QueryBuilderIcon />
            </ListItemIcon>
            <ListItemText primary="Query Management" />
          </ListItemButton>
        </Link>
        <Link to="dataentry" title="Data Entry Operator">
          <ListItemButton>
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            <ListItemText primary="Data entry" />
          </ListItemButton>
        </Link>
        <Link to="dataEntryReviewer" title="Data Entry Reviewer">
          <ListItemButton>
            <ListItemIcon>
              <AppRegistrationIcon />
            </ListItemIcon>
            <ListItemText primary="Data Reviewer" />
          </ListItemButton>
        </Link>
        <Link to="accessmanagement">
          <ListItemButton>
            <ListItemIcon>
              <AllOutIcon />
            </ListItemIcon>
            <ListItemText primary="Access Management" />
          </ListItemButton>
        </Link>
        {userType?.type !== "Sponsor" && (
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Onboarding" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        )}
        {userType?.type !== "Sponsor" && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ pl: 3 }}>
              {(userType?.type === "Admin" || userType?.type === "User") && (
                <Link to="onboarding-user">
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="User" />
                  </ListItemButton>
                </Link>
              )}
              {userType?.type === "Admin" && (
                <Link to="onboarding-client">
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Client" />
                  </ListItemButton>
                </Link>
              )}
              {(userType?.type === "Client" ||
                userType?.type === "Client User") && (
                <Link to="onboarding-client-user">
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="User" />
                  </ListItemButton>
                </Link>
              )}
              {userType?.type === "Client" && (
                <Link to="onboarding-sponser">
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sponser" />
                  </ListItemButton>
                </Link>
              )}
            </List>
          </Collapse>
        )}
        <Link to="backupmanagement">
          <ListItemButton>
            <ListItemIcon>
              <SelectAllIcon />
            </ListItemIcon>
            <ListItemText primary="Backup Management" />
          </ListItemButton>
        </Link>
        <Link to="logout">
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </Link>
      </List>
    </React.Fragment>
  );
}
