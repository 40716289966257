import React, { useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AppSMService } from "../../../services/app.service";
var breadCrumbList = [
  { title: "Home", link: "/clinicalApp/dashboard" },
  { title: "Query Management", link: "" },
];

const Querymanagement = () => {
  const [userList, setUserList] = useState([]);

  React.useEffect(() => {
    loadDataEntry();
  }, []);

  const loadDataEntry = () => {
    AppSMService.axios
      .get("query?fieldId=123")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setUserList(res.data);
          }
        }
      })
      .catch((e) => {
        console.log(`Error in creating new field - ${e}`);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open1, setOpen] = React.useState(false);
  const handleClickOpen = (ID: string) => {
    setOpen(true);
    setAnchorEl(null);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Query ID",
      width: 200,
      editable: false,
    },
    {
      field: "assigne",
      headerName: "Assigne",
      width: 300,
      editable: false,
    },
    {
      field: "formname",
      headerName: "From Name",
      width: 200,
      editable: false,
    },
    {
      field: "formcode",
      headerName: "Form Code",
      width: 250,
      editable: false,
    },
    {
      field: "modifiedOn",
      headerName: "Update On",
      width: 250,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        console.log(params.id, "params");
        return (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={(event) => handleClickOpen("1")}>
                Assign{params.id}
              </MenuItem>
              <MenuItem onClick={(event) => handleClickOpen("2")}>
                Unassign
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const rowsUpdated = userList;

  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rowsUpdated}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
            />
          </div>
        </Paper>
      </Grid>
      <BootstrapDialog
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={open1}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose1}
        >
          Assign Users
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ minWidth: 500 }}>
          <FormControl variant="standard" fullWidth sx={{ mb: 4 }}>
            <InputLabel id="demo-simple-select-standard-label">User</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              /* value={age} */
              /*  onChange={handleChange} */
              label="Age"
            >
              <MenuItem value={10}>User 1</MenuItem>
              <MenuItem value={20}>User 2</MenuItem>
              <MenuItem value={30}>User 3</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose1}
            variant="contained"
            style={{
              backgroundColor: "#0E3D67",
            }}
          >
            ASSIGN
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default Querymanagement;

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
