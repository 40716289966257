import React, { useContext, useEffect, useState } from "react";
import { getFormDetails } from "../../../common/util";
import AppContext from "../../../contexts/application.context";
import { IClinicalForm } from "../../../models/ClinicalForm";
import { IFormElement } from "../../../models/Common";
import FormViewerFieldRenderer from "../FormViewerFieldRenderer/FormViewerFieldRenderer";
interface IProps{
    formId: string;
    handleStatusChange?: () => void;
    openFieldReviewComments?: (fieldName: string) => void
}
const FormViewer = (props: IProps) => {
    const appContext = useContext(AppContext);
    const [formDetails, setFormDetails] = useState<IClinicalForm | null | undefined>();
    const loadFormDetails = (formId: string) => {
        console.log("formid", formId);
        const existingForms = [...appContext.forms || []];        
        const formData = getFormDetails(existingForms, formId);
        if(formData && formData?.data && typeof(formData.data) === "string"){
            formData.data = JSON.parse(formData?.data);
        }
        console.log(formData?.data);
        setFormDetails(formData);
    }
    useEffect(() => {
        setFormDetails(null);
        props.formId && loadFormDetails(props.formId);
    }, [props.formId])
    return (
        <p>{formDetails?.data && formDetails?.data.length > 0 && formDetails?.data.map((item:IFormElement, index: number) => {
            return <FormViewerFieldRenderer formElement={item} handleStatusChange={() => props?.handleStatusChange?.()} openFieldReviewComments={props.openFieldReviewComments} showComments={index % 2 ? true : false }/>
        })}</p>
    )
}
export default FormViewer;