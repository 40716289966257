import { IUser } from "./User";

export interface IOption {
    label?: string;
    value?: string;
    id?: string;
    selected?: boolean;
}
export enum FORMELEMENTTYPE{
    TEXT = "text",
    TEXTAREA = "textarea",
    SELECT = "select",
    RADIOGROUP = "radio-group",
    NUMBER = "number",
    CHECKBOXGROUP = "checkbox-group",
    DATE = "date",
    PARAGRAPH = "paragraph"
}
export enum FORMELEMENTSTATUS{
    APPROVED = "approved",
    REJECTED = "rejected"
}
export interface IComment {
    text: string;
    timestamp: string;
    user: IUser;
    status: string;
}
export interface IReviewerComments {
    reviewer: IUser;
    comments: IComment[];
}
export interface IFormElement{
    id?: string;
    label: string;
    name: string;
    type: FORMELEMENTTYPE;
    subtype?: string;
    className?: string;
    values?:IOption[];
    status?: FORMELEMENTSTATUS;
    reviewComments?:IReviewerComments[]
}
export const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];