import React from "react";
import { Typography } from "@mui/material";
interface IProps{
    label: string;
}
const LabelText = (props: IProps) => {
    return (
        <Typography variant="subtitle1" gutterBottom>
            {props.label}
        </Typography>
    )
}
export default LabelText;