import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { FORMELEMENTSTATUS, FORMELEMENTTYPE, IFormElement } from "../../../models/Common";
import LabelText from "../../atoms/LabelText/LabelText";
import FormInputText from "../../atoms/FormInputText/FormInputText";
import FormSelectBox from "../../molecules/FormSelectBox/FormSelectBox";
import FormRadioGroup from "../../molecules/FormRadioGroup/FormRadioGroup";
import FormCheckboxGroup from "../../molecules/FormCheckboxGroup/FormCheckboxGroup";
import FormDatePicker from "../../molecules/FormDatePicker/FormDatePicker";
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
//import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FormViewerFieldStatus from "../FormViewerFieldStatus/FormViewerFieldStatus";
import AppContext from "../../../contexts/application.context";
import { getFormDetails } from "../../../common/util";
import { useParams } from "react-router-dom";
interface IProps{
    formElement: IFormElement;
    showComments?:boolean;
    handleStatusChange?: () => void;
    openFieldReviewComments?: (fieldName: string) => void;
}
const FormViewerFieldRenderer = (props: IProps) => {
    const appContext = useContext(AppContext);
    const params = useParams();
    const [formField, setFormField] = useState<IFormElement>(props.formElement);
    const renderFormElement = (item: IFormElement) => {
        switch (item.type){
            case FORMELEMENTTYPE.PARAGRAPH:
                return <LabelText label={item.label}/>
            case FORMELEMENTTYPE.TEXT:
            case FORMELEMENTTYPE.TEXTAREA:
            case FORMELEMENTTYPE.NUMBER:
                return <FormInputText id={item.id} type={item.type} label={item.label} name={item.name} disabled={true}/>
            case FORMELEMENTTYPE.SELECT:
                return <FormSelectBox id={item.id} label={item.label} value={item.label} isDisabled={true} options={item.values}/>
            case FORMELEMENTTYPE.RADIOGROUP:
                return <FormRadioGroup id={item.id} value={item.label} isDisabled={true} options={item.values}/>
            case FORMELEMENTTYPE.CHECKBOXGROUP:
                return <FormCheckboxGroup id={item.id} value={item.label} isDisabled={true} options={item.values}/>
            case FORMELEMENTTYPE.DATE:
                return <FormDatePicker id={item.id} value={item.label} disabled={true} label={item.label}/>
            default:
                return item.type;
        }
    }
    const updateFormFieldStatus = (status: FORMELEMENTSTATUS) => {
        setFormField({...formField, status: status});
        //TEMP CHange
        const existingForms = [...appContext.forms || []];
        if(params.formId){
            const formData = getFormDetails(existingForms, params.formId);
            if(formData){
                //formData.data = formObj;
                const formFields = structuredClone(formData.data);
                const currentFieldIndex = formFields.findIndex((item: IFormElement) => item.name === formField.name);
                console.log("currentFieldIndex", currentFieldIndex);
                if(currentFieldIndex >= 0 && formFields[currentFieldIndex]){
                    formFields[currentFieldIndex].status = status;
                }
                formData.data = formFields;
                //formData.lastUpdated = currTime;
                appContext.forms = existingForms;
                console.log(appContext.forms);
            }
            props?.handleStatusChange?.();
        }
    }
    useEffect(() => {
        setFormField(props.formElement);
    },[props.formElement])
    return (
        <Box sx={{display: "flex", height: '100%', marginLeft: "10px", }}>
            <Box sx={{width: "5%", display: "flex" , padding: "10px", textAlign: "left", alignItems:"center", cursor: "pointer"}} title="Comments" onClick={() => props.openFieldReviewComments?.(formField.name)}>{props.showComments && <MarkUnreadChatAltOutlinedIcon />}</Box>
            <Box sx={{width: "85%", display: "flex" , flexDirection: "column", padding: "10px", textAlign: "left"}}>
                <div>{formField.label}</div>
                {renderFormElement(formField)}
            </Box>
            <FormViewerFieldStatus currentStatus={formField.status} handleStatusChange={updateFormFieldStatus}/>
        </Box>
    )
}

export default FormViewerFieldRenderer;