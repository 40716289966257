import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MasterLayout from "../../templates/MasterLayout";
import AppContext from "../../../contexts/application.context";
import { AppService } from "../../../services/app.service";

export interface IClinicalAppProps {}
function ClinicalApp(props: IClinicalAppProps) {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  React.useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    AppService.axios
      .get("users/me")
      .then((res) => {
        if (res.status === 200) {
          context.user = {
            id: res.data.principal.id,
            name:
              res.data.principal.firstName + " " + res.data.principal.lastName,
            type: res.data.principal.userType,
          };
        }
      })
      .catch((e) => {
        navigate("/");
        console.log(`Error in get user - ${e}`);
      });
  };

  return <MasterLayout />;
}
export default ClinicalApp;
