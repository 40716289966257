import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { AppProvider } from "./contexts/application.context";
import { CssBaseline } from "@mui/material";
import Login from "./components/pages/Login/Login";
import Logout from "./components/pages/Logout/Logout";
import Error from "./components/pages/Error/Error";
import ClinicalApp from "./components/pages/ClinicalApp/ClinicalApp";
import FormsListPage from "./components/pages/FormsListPage/FormsListPage";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import FormViewerPage from "./components/pages/FormViewerPage/FormViewerPage";
import CreateClinicalForm from "./components/pages/CreateClinicalForm/CreateClinicalForm";
import FormBuilder from "./components/organisms/FormBuilder/FormBuilder";
import Querymanagement from "./components/pages/Querymanagement/Querymanagement";
import DataEntryOperator from "./components/pages/DataEntryOperator/DataEntryOperator";
import DataEntryForm from "./components/pages/DataEntryOperator/DataEntryForm";
import DataEntryReviewerGrid from "./components/pages/DataEntryReeviewer/DataEntryReviewerGrid";
import DataEntryReviewerForm from "./components/pages/DataEntryReeviewer/DataEntryReviewerForm";
import FormReviewerPage from "./components/pages/FormReviewerPage/FormReviewerPage";
import AccessManagement from "./components/pages/AccessManagement/AccessManagement";

import OnboardingUserGrid from "./components/pages/Onboarding/OnboardingUserGrid";
import OnboardingClientGrid from "./components/pages/Onboarding/OnboardingClientGrid";
import OnboardingClientUserGrid from "./components/pages/Onboarding/OnboardingClientUserGrid";
import OnboardingSponserGrid from "./components/pages/Onboarding/OnboardingSponserGrid";

import OnboardingGrid from "./components/pages/Onboarding/OnboardingGrid";
import OnboardingFrom from "./components/pages/Onboarding/OnboardingFrom";

/* import CamspansorForm from "./components/pages/Onboarding/CamspansorForm";
import SuperAdminForm from "./components/pages/Onboarding/SuperAdminForm";
import SamuserForm from "./components/pages/Onboarding/SamuserForm"; */

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#0c3d67",
    },
  },
});

root.render(
  <>
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <BrowserRouter>
        <AppProvider>
          <Routes>
            <Route path="clinicalApp" element={<ClinicalApp />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="forms">
                {/*Form Builder and reviewer list */}
                <Route path="list" element={<FormsListPage />} />
                {/*Form Builder - form viewer */}
                <Route path=":formId" element={<FormViewerPage />} />
                {/*Form Reviewer - form viewer */}
                <Route path=":formId/review" element={<FormReviewerPage />} />
                {/*Form Builder - create new form */}
                <Route path="builder/new" element={<CreateClinicalForm />} />
              </Route>
              <Route path="querymanagement" element={<Querymanagement />} />
              <Route path="dataEntry" element={<DataEntryOperator />} />
              <Route path="dataEntry/form" element={<DataEntryForm />} />
              <Route
                path="dataEntryReviewer"
                element={<DataEntryReviewerGrid />}
              />
              <Route
                path="dataEntryReviewer/form"
                element={<DataEntryReviewerForm />}
              />
              <Route path="accessmanagement" element={<AccessManagement />} />
              <Route path="onboarding-user" element={<OnboardingUserGrid />} />
              <Route
                path="onboarding-user/form/:formID"
                element={<OnboardingFrom />}
              />
              <Route path="onboarding-user/form" element={<OnboardingFrom />} />
              <Route
                path="onboarding-client"
                element={<OnboardingClientGrid />}
              />
              <Route
                path="onboarding-client/form/:formID"
                element={<OnboardingFrom />}
              />
              <Route
                path="onboarding-client/form"
                element={<OnboardingFrom />}
              />
              <Route
                path="onboarding-client-user"
                element={<OnboardingClientUserGrid />}
              />
              <Route
                path="onboarding-client-user/form"
                element={<OnboardingFrom />}
              />

              <Route
                path="onboarding-client/form/:formID"
                element={<OnboardingFrom />}
              />
              <Route
                path="onboarding-client/form"
                element={<OnboardingFrom />}
              />
              <Route
                path="onboarding-sponser"
                element={<OnboardingSponserGrid />}
              />
              <Route
                path="onboarding-sponser/form/:formID"
                element={<OnboardingFrom />}
              />
              <Route
                path="onboarding-sponser/form"
                element={<OnboardingFrom />}
              />
              <Route path="logout" element={<Logout />} />

              {/* <Route
                path="onboarding-camspansor"
                element={<CamspansorGrid />}
              />
              <Route
                path="onboarding-camspansor/form/:formID"
                element={<CamspansorForm />}
              />
              <Route
                path="onboarding-camspansor/form"
                element={<CamspansorForm />}
              /> */}

              {/* <Route
                path="onboarding-samuser/form/:formID"
                element={<SamuserForm />}
              />
              <Route path="onboarding-samuser/form" element={<SamuserForm />} /> */}
              {/* <Route
                path="backupmanagement"
                element={<BackupmanagementGrid />}
              /> */}
            </Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/" element={<Login />}></Route>
            <Route path="*" element={<Error />}></Route>
            {/* {ROUTING_PATH.map((route: IRoute) => {
              return (
                <Route key={route.path} path={route.path} Component={route.component}>
                  {route.children &&
                    route.children.length > 0 &&
                    route.children.map((childRoute: IRoute) => {
                      return <Route path={childRoute.path} Component={childRoute.component} />;
                    })}
                </Route>
              );
            })} */}
          </Routes>
        </AppProvider>
      </BrowserRouter>
    </ThemeProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
