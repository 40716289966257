import React, { useContext } from "react";
import FormContext from "../../../contexts/form.Context";
import { useNavigate } from "react-router-dom";
import FormBuilder from "../../organisms/FormBuilder/FormBuilder";
import AppContext from "../../../contexts/application.context";
import { IClinicalForm } from "../../../models/ClinicalForm";
export const demoComments = [
  {
    "reviewer": {
      "id": "1",
      "name": "Reviewer 1",
      "type": "reviewer"
    },
    "comments": [
      {
        "user": {
          "id": "1",
          "name": "Reviewer 1",
          "type": "reviewer"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      },
      {
        "user": {
          "id": "1",
          "name": "Builder",
          "type": "builder"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      },
      {
        "user": {
          "id": "1",
          "name": "Reviewer 1",
          "type": "reviewer"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      }
    ]
  },
  {
    "reviewer": {
      "id": "2",
      "name": "Reviewer 2",
      "type": "reviewer"
    },
    "comments": [
      {
        "user": {
          "id": "1",
          "name": "Builder",
          "type": "builder"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      },
      {
        "user": {
          "id": "1",
          "name": "Builder",
          "type": "builder"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      },
      {
        "user": {
          "id": "1",
          "name": "Builder",
          "type": "builder"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      }
    ]
  },
  {
    "reviewer": {
      "id": "3",
      "name": "Rreviewer 3",
      "type": "reviewer"
    },
    "comments": [
      {
        "user": {
          "id": "1",
          "name": "Rreviewer 3",
          "type": "reviewer"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      },
      {
        "user": {
          "id": "1",
          "name": "Builder",
          "type": "builder"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      },
      {
        "user": {
          "id": "1",
          "name": "Builder",
          "type": "builder"
        },
        "text": "Split as First Name & Last Name",
        "timestamp": "06 May 2023, 12:57",
        "status": "Resolved"
      }
    ]
  }
];
const CreateClinicalForm = () => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const handleFormData = (formObj: any) => {
    const currTime = new Date().getTime();
    const existingForms = [...appContext.forms || []];
    existingForms.push({
      id: currTime.toString(),
      name: `Form - ${existingForms?.length + 1}`,
      data: formObj,
      lastUpdated: currTime ,
      version: '1.0',
    } as IClinicalForm);
    appContext.forms = existingForms;
    navigate("/clinicalApp/forms/list");
  }
  return (
    <>
      <h2>Form Builder</h2>
      <FormBuilder handleFormData={handleFormData}/>
    </>
  );
};
export default CreateClinicalForm;
