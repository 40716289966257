import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
interface IAccess {
  id: string;
  name: string;
  accessManagementId: number;
}
interface IAccessRights {
  id: string;
  name: string;
  checked: string;
}
/* IAccessRights */
export interface IAccessDataProps {
  selectedAccess: string[];
  masteraccessRights: IAccess[];
  selectedAccessID: string;
  handleSelectAccess: (value: any) => void;
}

function AccessRights(props: IAccessDataProps) {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      {props.masteraccessRights.map((item: any, index) => {
        if (props.selectedAccess.indexOf(item.id) >= 0) {
          return (
            <span key={index}>
              <Checkbox
                inputProps={{
                  "aria-label": "Checkbox A",
                }}
                checked={
                  props.selectedAccess.indexOf(item.id) >= 0 ? true : false
                }
                value={item.id}
                onChange={(event) => props.handleSelectAccess(event)}
              />
              {item.name}
            </span>
          );
        }
      })}
    </Box>
  );
}

export default AccessRights;
