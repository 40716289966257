import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import { ClearAllOutlined, SaveOutlined } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import TabHeader from "../../organisms/TabHeader/TabHeader";
import AccessRights from "../../organisms/AccessRights/AccessRights";
import AccessRightsSelect from "../../organisms/AccessRightsSelect/AccessRightsSelect";
import { AppService } from "../../../services/app.service";
import Dialog from "../../molecules/Dialog/Dialog";
import Alert from "../../molecules/Alert/Alert";
var breadCrumbList = [
  { title: "Home", link: "/clinicalApp/dashboard" },
  { title: "Access Management", link: "" },
];
interface IAccessList {
  id: string;
  name: string;
  description: string;
  authority: string;
}
interface IAccess {
  id: string;
  name: string;
  accessManagementId: number;
}

interface IAccessMangement {
  id: number;
  title: string;
  name: string;
  description: string;
  accessrights: any;
}

interface IAccessMGT {
  accessrights: IAccess[];
  accessmangement: IAccessMangement[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function AccessManagement() {
  const [accessManagemnet, setManagemnet] = useState<IAccessList[]>([]);
  const [accessRights, setAccessRights] = useState<IAccess[]>([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [btnloading, setBtnLoading] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [openAlertMsg, setopenAlertMsg] = useState("");
  const [openAlertStatus, setopenAlertStatus] = useState("");

  const [selectedAccess, setSelectedAccess] = useState<any>([]);
  const [selectedAllAccess, setselectedAllAccess] = useState<any>([]);
  const [selectedAccessMappingID, setSelectedAccessMappingID] = useState("");
  const [selectedAccessID, setSelectedAccessID] = useState<any>("");
  const [openDialog, setopenDialog] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTab, setselectedTab] = useState(0);
  const [removeItem, setRemoveItem] = useState(0);

  useEffect(() => {
    loadaccessRights();
  }, []);
  useEffect(() => {
    loadAccessData();
  }, [accessRights]);

  useEffect(() => {
    if (selectedAllAccess.length > 0) {
      if (selectedAccessID === "0") {
        setSelectedAccess([]);
        setSelectedAccessMappingID("");
      } else {
        var availAccess = "No";
        selectedAllAccess.map((item: any) => {
          if (item.accessManagementId === selectedAccessID) {
            availAccess = "Yes";
            setSelectedAccess(item.accessRightsIds);
            setSelectedAccessMappingID(item.id);
          }
        });
        if (availAccess === "No") {
          setSelectedAccess([]);
          setSelectedAccessMappingID("");
        }
      }
    } else {
      loadAccessRightMaping();
    }
  }, [selectedAccessID]);

  const loadAccessData = () => {
    AppService.axios
      .get("accessmanagements")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            var updaccessmangement = res.data;
            setManagemnet(updaccessmangement);
            setName(updaccessmangement[activeTab].name);
            setSelectedAccessID(updaccessmangement[activeTab].id);
            setDescription(updaccessmangement[activeTab].description);
          }
        }
      })
      .catch((e) => {
        console.log(`Error in AccessManagement - ${e}`);
      });
  };
  const loadaccessRights = () => {
    AppService.axios
      .get("accessrights")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setAccessRights(res.data);
          }
        }
      })
      .catch((e) => {
        console.log(`Error in AccessRights - ${e}`);
      });
  };
  const loadAccessRightMaping = () => {
    AppService.axios
      .get(`accessrightsmanagementsmapping`)
      .then((res) => {
        if (res.status === 200) {
          var result = res.data;
          if (result.length > 0) {
            setSelectedAccessID(selectedAccessID);
            setselectedAllAccess(result);
          }
        }
      })
      .catch((e) => {
        console.log(`Error in AccessRights - ${e}`);
      });
  };

  const handleSetActiveTab = (value: number) => {
    setActiveTab(value);
    setName(accessManagemnet[value].name);
    var selectID = accessManagemnet[value].id;
    setSelectedAccessID(selectID);
    setDescription(accessManagemnet[value].description);
  };

  const loadTab = () => {
    if (accessManagemnet.length > 0) {
      var tabHeader = new Array();
      accessManagemnet.map((item: any) => {
        tabHeader.push(item.name);
      });

      return (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabHeader
            headerlist={tabHeader}
            handleSetActiveTab={handleSetActiveTab}
            selectedTab={selectedTab}
          />
        </Box>
      );
    }
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [options, setOptions] = React.useState<string[]>([]);

  const selectAccess = () => {
    var updatedAccess = selectedAccess;
    if (options.length > 0) {
      accessRights.map((item: any) => {
        if (options.indexOf(item.name) >= 0) {
          updatedAccess.push(item.id);
        }
      });
    }
    setSelectedAccess(updatedAccess);
    setOpen(false);
  };

  const handleSetOptions = (value: string[]) => {
    setOptions(value);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: string
  ) => {
    var value = event.target.value;
    if (type === "name") {
      setName(value.toLocaleUpperCase());
    } else if (type === "description") {
      setDescription(value);
    }
  };

  const handleSelectAccess = (event: React.ChangeEvent<HTMLInputElement>) => {
    var updatevalue = selectedAccess;
    var selectedVal = event.target.value;
    const index = updatevalue.indexOf(selectedVal);
    if (index > -1) {
      updatevalue.splice(index, 1);
    }
    setSelectedAccess(updatevalue);
    setRemoveItem(Math.random());
  };

  const saveChanges = () => {
    setBtnLoading(true);
    if (accessManagemnet[activeTab].id === "0") {
      AppService.axios
        .post(`accessmanagements`, {
          name: name,
          description: description,
          authority: localStorage.getItem("authority"),
        })
        .then((res) => {
          if (res.status === 200) {
            setopenAlert(true);
            setopenAlertStatus("success");
            setopenAlertMsg("Access Management Update Success");
            loadAccessData();
            assignAccessRights(res.data.id);
          } else {
            setopenAlert(true);
            setopenAlertStatus("error");
            setopenAlertMsg("Access Management Not Update");
          }
          AutoCloseAlert();
          setBtnLoading(false);
        })
        .catch((e) => {
          setopenAlert(true);
          setopenAlertStatus("error");
          setopenAlertMsg("Access Management Not Update");
          AutoCloseAlert();
          console.log(`Error in Login - ${e}`);
        });
    } else {
      AppService.axios
        .put(`accessmanagements/${accessManagemnet[activeTab].id}`, {
          name: name,
          description: description,
          authority: localStorage.getItem("authority"),
        })
        .then((res) => {
          if (res.status === 200) {
            setopenAlert(true);
            setopenAlertStatus("success");
            setopenAlertMsg("Access Management Update Success");
            loadAccessData();
            assignAccessRights(selectedAccessID);
          } else {
            setopenAlert(true);
            setopenAlertStatus("error");
            setopenAlertMsg("Access Management Not Update");
          }
          AutoCloseAlert();
          setBtnLoading(false);
        })
        .catch((e) => {
          setopenAlert(true);
          setopenAlertStatus("error");
          setopenAlertMsg("Access Management Not Update");
          AutoCloseAlert();
          console.log(`Error in Login - ${e}`);
        });
    }
  };
  const assignAccessRights = (selectedAccess_ID: any) => {
    if (selectedAccessMappingID !== "") {
      AppService.axios
        .put(`accessrightsmanagementsmapping/${selectedAccessMappingID}`, {
          accessRightsIds: selectedAccess,
          accessManagementId: selectedAccess_ID,
        })
        .then((res) => {
          if (res.status === 200) {
            loadAccessRightMaping();
          }
        })
        .catch((e) => {
          console.log(`Error in AccessRightsMapping - ${e}`);
        });
    } else {
      AppService.axios
        .post(`accessrightsmanagementsmapping`, {
          accessRightsIds: selectedAccess,
          accessManagementId: selectedAccess_ID,
        })
        .then((res) => {
          if (res.status === 200) {
            loadAccessRightMaping();
          }
        })
        .catch((e) => {
          console.log(`Error in AccessRightsMapping - ${e}`);
        });
    }
  };
  const handleCloseAlert = () => {
    setopenAlert(false);
    setopenAlertStatus("");
    setopenAlertMsg("");
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      setopenAlert(false);
      setopenAlertStatus("");
      setopenAlertMsg("");
    }, 3000);
  };
  const handleRemove = () => {
    setopenDialog(true);
  };
  const handleCloseDialog = () => {
    setopenDialog(false);
  };
  const handleAcceptDialog = () => {
    if (selectedAccessID === "0") {
      loadAccessData();
      handleCloseDialog();
      handleSetActiveTab(0);
      setselectedTab(0);
    } else {
      AppService.axios
        .delete(`accessmanagements/${selectedAccessID}`)
        .then((res) => {
          if (res.status === 200) {
            removeAcceessMaping();
            setselectedTab(0);
          } else {
            handleCloseDialog();
            setopenAlert(true);
            setopenAlertStatus("error");
            setopenAlertMsg(res.data.Message);
          }
        })
        .catch((e) => {
          handleCloseDialog();
          setopenAlert(true);
          setopenAlertStatus("error");
          setopenAlertMsg("Can't delete access management");
          console.log(`Error in AccessRightsMapping - ${e}`);
        });
    }
  };
  const removeAcceessMaping = () => {
    AppService.axios
      .delete(`accessrightsmanagementsmapping/${selectedAccessMappingID}`)
      .then((res) => {
        if (res.status === 200) {
          loadAccessData();
          handleCloseDialog();
        }
      })
      .catch((e) => {
        loadAccessData();
        handleCloseDialog();
        console.log(`Error in AccessRightsMapping - ${e}`);
      });
  };
  const addCustom = () => {
    var access_Managemnet = accessManagemnet;
    accessManagemnet.push({
      id: "0",
      name: "Custom",
      description: "",
      authority: "",
    });

    setName("Custom");
    setSelectedAccessID("0");
    setDescription("");
    setManagemnet(access_Managemnet);
    setActiveTab(access_Managemnet.length - 1);
    handleSetActiveTab(access_Managemnet.length - 1);
    setselectedTab(access_Managemnet.length - 1);
  };
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Grid container>
            <Breadcrumb breadCrumbList={breadCrumbList} />
            <Paper sx={{ width: 1 }}>
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                sx={{ marginTop: "10px", marginRight: "10px" }}
              >
                <Button variant="contained" onClick={addCustom}>
                  Create New
                </Button>
              </Stack>

              {loadTab()}
              <CustomTabPanel value={activeTab} index={activeTab}>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    value={name}
                    variant="standard"
                    sx={{ marginRight: "20px", width: "25%" }}
                    onChange={(event) => handleTextFieldChange(event, "name")}
                  />
                  <TextField
                    label="Description"
                    value={description}
                    onChange={(event) =>
                      handleTextFieldChange(event, "description")
                    }
                    sx={{ width: "70%" }}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "10px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="h5">Access Rights</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        direction="row"
                        alignItems="right"
                        justifyContent="right"
                      >
                        <Button variant="contained" onClick={handleOpen}>
                          Add
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>

                  {selectedAccess.length > 0 && (
                    <AccessRightsSelect
                      selectedAccess={selectedAccess}
                      masteraccessRights={accessRights}
                      selectedAccessID={selectedAccessID}
                      handleSelectAccess={handleSelectAccess}
                    />
                  )}
                </Grid>
              </CustomTabPanel>
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                spacing={2}
                pt={4}
                pb={2}
                mr={4}
              >
                <Button
                  variant="outlined"
                  endIcon={<DeleteIcon />}
                  onClick={handleRemove}
                >
                  Remove
                </Button>
                <LoadingButton
                  onClick={saveChanges}
                  loading={btnloading}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Stack>
            </Paper>
          </Grid>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AccessRights
          accessRights={accessRights}
          selectedAccess={selectedAccess}
          selectedAccessID={selectedAccessID}
          handleSetOptions={handleSetOptions}
          handleClose={handleClose}
          selectAccess={selectAccess}
        />
      </Modal>
      <Dialog
        openDialog={openDialog}
        dialogMsg="Are you sure want to remove this Access Management?"
        dialogHeader={"Alert"}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </>
  );
}
export default AccessManagement;
