import { Box } from "@mui/material";
import axios from "axios";
import { cp } from "fs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../contexts/application.context";
import { IClinicalForm } from "../../../models/ClinicalForm";
import { AppService } from "../../../services/app.service";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import FormBuilder from "../../organisms/FormBuilder/FormBuilder";
import FormsList from "../../organisms/FormsList/FormsList";
interface IFormViewer{
  formId?: string;
  formData?: string;
}
const FormViewerPage = (props: IFormViewer) => {
  const appContext = useContext(AppContext);
  const [formDetails, setFormDetails] = useState<IClinicalForm | null>(null);
  const [formFields, setFormFields] = useState<any[]>([]);
  const params = useParams();
  const navigate = useNavigate();
  //const mockFormFields = {fields: [{"createdOn":"2023-10-01T12:42:07.206Z","modifiedOn":"2023-10-01T12:38:58.084Z","formId":"54","definition":{"type":"checkbox-group","label":"Checkbox Group","name":"field-name-12","values":[{"label":"Option 1","value":"option-1","selected":true}]},"id":"12"},{"createdOn":"2023-10-02T05:13:29.813Z","modifiedOn":"2023-10-02T04:27:17.255Z","formId":"54","definition":{"type":"date","label":"Date Field","className":"form-control","name":"field-name-13"},"id":"13"},{"createdOn":"2023-10-01T15:30:56.741Z","modifiedOn":"2023-10-01T09:32:34.361Z","formId":"54","definition":{"type":"number","label":"Number","className":"form-control","name":"field-name-14"},"id":"14"}]};
  const mockFormFields ={fields: [
    {
      "createdOn": "2023-10-02T13:09:37.621Z",
      "modifiedOn": "2023-10-02T17:28:11.186Z",
      "formId": "54",
      "definition": {
        "type": "checkbox-group",
        "label": "Checkbox Group",
        "name": "field-name-12",
        "values": [
          {
            "label": "Option 1",
            "value": "option-1",
            "selected": true
          }
        ]
      },
      "id": "12"
    },
    {
      "createdOn": "2023-10-03T00:47:07.470Z",
      "modifiedOn": "2023-10-03T08:17:10.861Z",
      "formId": "54",
      "definition": {
        "type": "date",
        "label": "Date Field",
        "className": "form-control",
        "name": "field-name-13"
      },
      "id": "13"
    },
    {
      "createdOn": "2023-10-03T11:48:43.868Z",
      "modifiedOn": "2023-10-03T11:55:59.554Z",
      "formId": "54",
      "definition": {
        "type": "number",
        "label": "Number",
        "className": "form-control",
        "name": "field-name-14"
      },
      "id": "14"
    }
  ]};
  const [
    { data: getData, loading, error },
    fetchData
  ] = AppService.axiosHooks(
    {
      url: `forms/${params.formId}`,
      method: "GET",
      params: {studyId: appContext.metaData?.studyId}
    },
    { manual: true }
  );
  const getFormDetails = (formId: string) => {
    if(formId){
      fetchData().then((res: any) => {
        console.log(res);
        setFormDetails({...res, fields: mockFormFields.fields});
        //setFormDetails({...res});
        const formFields: any[] = [];
        mockFormFields.fields.forEach((field) => {
          formFields.push({...field.definition, id: field.id, fieldId: field.id, dataIdVal: field.id, name: `field-name-${field.id}`});
        });
        setFormFields(formFields);
      }).catch((e: any) => {
        console.log(`Error in fetching forms list - ${e}`);
      })
    }
  }
  const handleFormData = (formObj: any) => {
    const prevFormDetails = {...formDetails};
    const prevFormFields = prevFormDetails.fields || [];
    const promises: any[] = [];
    const prevFieldIds = prevFormFields.map((field) => field.id);
    const currentFieldIds = formObj.map((field: any) => {
      const fieldId = field.name.split("field-name-")[1];
      return fieldId;
    });
    console.log("currentFieldIds", currentFieldIds);
    const fieldsToDelete = prevFieldIds.filter((fieldId) => !currentFieldIds.includes(fieldId));
    console.log("fieldsToDelete", fieldsToDelete);
    formObj?.length > 0 && formObj.forEach((field: any) => {
      console.log("handleFormData", field);
      const fieldId = field.name.split("field-name-")[1];
      console.log("fieldId", fieldId);
      const prevData = prevFormFields.find((savedField) => savedField.id === fieldId)?.definition || null;
      if(fieldId && prevData){        
        console.log("handleFormData - field id", prevData);
        console.log(JSON.stringify(prevData));
        console.log("current field data");
        console.log(JSON.stringify(field));
        if(JSON.stringify(prevData) !== JSON.stringify(field)){
          const patchFormField = AppService.axios.put(`fields/${fieldId}`,{definition: field}).then((res) => {
            console.log(res);
          }).catch((e) => {
            console.log(`Error in updating field ${fieldId} - ${e}`);
          })
          promises.push(patchFormField)
        } else {
          console.log(`No change in form field - ${field.id}`);
        }
      } else {
        const createFormField = AppService.axios.post("fields",{formId: params.formId, definition: field}).then((res) => {
          console.log(res);
          //navigate("/clinicalApp/forms/list");
        }).catch((e) => {
          console.log(`Error in creating new field - ${e}`);
        })
        promises.push(createFormField);
      }
    });
    if(fieldsToDelete && fieldsToDelete.length > 0){
      fieldsToDelete.forEach((fieldId) => {
        const deleteFormField = AppService.axios.delete(`fields/${fieldId}`).then((res) => {
          console.log(res);
        }).catch((e) => {
          console.log(`Error in creating new field - ${e}`);
        })
        promises.push(deleteFormField);
      });
    }
    axios.all(promises).then(() => {
      console.log("all api calls succeeded");
      navigate("/clinicalApp/forms/list");
    }).catch((e) => {
      console.log(`Error in updating the fields - ${e}`);
    })
  }
  
  const handleSelectedForm = (formId: string) => {
    formId && navigate(`/clinicalApp/forms/${formId}`);
  }
  useEffect(() => {
    getFormDetails(params.formId || "");
  }, [params.formId])
  useEffect(() => {
    getFormDetails(params.formId || "");
  }, [])
  return <>
    {/* <p>Form Editor - {formDetails?.name}</p> */}
    <Breadcrumb breadCrumbList={[{title: "Home", navigateTo: "/clinicalApp/forms/list"}, {title: "Client 1"},{title: "Project 1"},{title: "Study 1"}]} />
    <Box sx={{display: "flex", height: '100%'}}>
      <Box sx={{width: "200px", maxWidth: "200px", overflowX:"auto", border: "1px #ccc solid", height: '100%'}}>
        {<FormsList selectedFormId={params.formId} handleSelectedItem={handleSelectedForm}/> }
      </Box>
      <FormBuilder handleFormData={handleFormData} formFields={formFields}/>
    </Box>
  </>;
};
export default FormViewerPage;
