import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { configure, makeUseAxios, UseAxios } from "axios-hooks";
export interface IAppService {
    axios: AxiosInstance;
    axiosHooks: UseAxios;
}
const AppService: IAppService = {} as IAppService;
const AppSMService: IAppService = {} as IAppService;
const axiosInstance =  axios.create({
    baseURL: /* "https://65191904818c4e98ac600c03.mockapi.io/api/v1/", */'https://um.medistatshytech.net/',
    headers: {
        "Content-Type": "application/json",
    },
})
axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
        if(config && config.headers){
            config.headers['authorization'] = `Bearer `+localStorage.getItem('token');
        }
        return config;
    }
);
axiosInstance.interceptors.response.use(async (response) => {
    return response;
  }, (err) => {
   return err.response ;
   //401
    //localStorage.removeItem("token");
    //window.location.href = window.location.protocol + "//" + window.location.host;
   // return Promise.reject(err);
  });


const axiossmInstance =  axios.create({
    baseURL: /* "https://65191904818c4e98ac600c03.mockapi.io/api/v1/", */'https://sm.medistatshytech.net/api/v1/',
    headers: {
        "Content-Type": "application/json",
    },
})

/* axiossmInstance.interceptors.response.use(async (response) => {
    return response;
  }, (err) => {
  // console.log(err.status, 'errerrerrerr')
    //localStorage.removeItem("token");
    //window.location.href = window.location.protocol + "//" + window.location.host;
   // return Promise.reject(err);
  });
 */



configure({ axios });
const axiosHooks = makeUseAxios({
    axios: axiosInstance
});
AppService.axios = axiosInstance;
AppService.axiosHooks = axiosHooks;
AppSMService.axios = axiossmInstance;
export { AppService, AppSMService };