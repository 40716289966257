import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Stack,
  Tab,
  Tabs,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";

import Avatar from "@mui/material/Avatar";
import { SelectChangeEvent } from "@mui/material/Select";
import Comments from "../Comments/Comments";
import TabHeader from "../TabHeader/TabHeader";
import { IReviewerComments } from "../../../models/Common";

interface foremParam {
  isDataEntryOperator: boolean;
  addPopupcloseHandler: any;
  reviewercommentlist: IReviewerComments[];
  saveComments: (value: number) => void;
  setCommentText: (value: string) => void;
  commentText: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function commentProps(index: number) {
  return {
    id: `comment-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Comment(props: foremParam) {
  const [commentvalue, setCommentValue] = useState(0);

  const handleCommentChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setCommentValue(newValue);
  };

  const handleCommentFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.setCommentText(event.target.value);
  };
  const handleCommentSelectFieldChange = (event: SelectChangeEvent<any>) => {
    props.setCommentText(event.target.value);
  };
  const handleSetActiveTab = (value: number) => {
    setCommentValue(value);
  };
  const loadTab = () => {
    if (props.reviewercommentlist.length > 0) {
      var tabHeader = new Array();
      if (props.isDataEntryOperator === true) {
        props.reviewercommentlist.map((item: any) => {
          tabHeader.push(item.reviewer.name);
        });
      } else {
        tabHeader = ["Predefined Queries", "Add Manually"];
      }

      return (
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabHeader
            headerlist={tabHeader}
            handleSetActiveTab={handleSetActiveTab}
          />
        </Box>
      );
    }
  };

  return (
    <>
      {loadTab()}
      {props.isDataEntryOperator === true ? (
        <CustomTabPanel value={commentvalue} index={commentvalue}>
          {props.reviewercommentlist.length > 0 ? (
            <Box
              sx={{
                mb: 2,
                display: "flex",
                flexDirection: "column",
                height: 250,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              <Comments
                comments={props.reviewercommentlist[commentvalue].comments}
              />
            </Box>
          ) : (
            <Box
              sx={{
                mb: 2,
                display: "inline-flex",
                VerticalAlign: "text-bottom",
                BoxSizing: "inherit",
                textAlign: "center",
                AlignItems: "center",
                height: 250,
              }}
            >
              <Typography>There are no queries</Typography>
            </Box>
          )}
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="input-with-icon-adornment">
                Comments
              </InputLabel>
              <Input
                style={{
                  width: "100%",
                }}
                onChange={(event) => handleCommentFieldChange(event)}
                value={props.commentText}
              />
            </FormControl>
          </Box>
          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            spacing={2}
            pt={4}
            pb={2}
          >
            <Button variant="outlined" onClick={props.addPopupcloseHandler}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={(event) => props.saveComments(commentvalue)}
            >
              Reply
            </Button>
          </Stack>
        </CustomTabPanel>
      ) : (
        <>
          {props.reviewercommentlist.length > 0 ? (
            <Box
              sx={{
                mb: 2,
                mt: 5,
                display: "flex",
                flexDirection: "column",
                height: 250,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              <Comments comments={props.reviewercommentlist[0].comments} />
            </Box>
          ) : (
            <Box
              sx={{
                mb: 2,
                display: "inline-flex",
                VerticalAlign: "text-bottom",
                BoxSizing: "inherit",
                textAlign: "center",
                AlignItems: "center",
                height: 250,
              }}
            >
              <Typography>There are no queries</Typography>
            </Box>
          )}
          <CustomTabPanel value={commentvalue} index={0}>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="input-with-icon-adornment">
                  Predefined Queries
                </InputLabel>
                <Select
                  value={props.commentText}
                  style={{
                    width: "100%",
                  }}
                  onChange={(event) => handleCommentSelectFieldChange(event)}
                >
                  <MenuItem value="Value">Value</MenuItem>
                  <MenuItem value="Field">Field</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Stack
              direction="row"
              alignItems="right"
              justifyContent="right"
              spacing={2}
              pt={4}
              pb={2}
            >
              <Button variant="outlined" onClick={props.addPopupcloseHandler}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={(event) => props.saveComments(0)}
              >
                Add
              </Button>
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel value={commentvalue} index={1}>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="input-with-icon-adornment">
                  Comments
                </InputLabel>
                <Input
                  style={{
                    width: "100%",
                  }}
                  onChange={(event) => handleCommentFieldChange(event)}
                  value={props.commentText}
                />
              </FormControl>
            </Box>
            <Stack
              direction="row"
              alignItems="right"
              justifyContent="right"
              spacing={2}
              pt={4}
              pb={2}
            >
              <Button variant="outlined" onClick={props.addPopupcloseHandler}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={(event) => props.saveComments(0)}
              >
                Add
              </Button>
            </Stack>
          </CustomTabPanel>
        </>
      )}
    </>
  );
}
export default Comment;
