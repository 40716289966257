import { IFormElement } from "./Common";

export interface IFormMetadata{
    clientId: string;
    projectId: string;
    studyId: string;
}
export enum FORMSTATUS{
    APPROVED = "Approved",
    REJECTED = "Rejected",
    INPROGRESS = "In-Progress",
    PENDING = "Pending"
}
export interface IClinicalForm {
    id?: string;
    name?: string;
    data: IFormElement[];
    lastUpdated?: number;
    status?:FORMSTATUS;//Pending, In Progress
    metaData?:IFormMetadata;
    version?:string;
    fields?:any[];
}