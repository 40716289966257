import { AppBar, Box, Button, FormControl, FormLabel, Input, Tab, Tabs, TextField, Toolbar, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../contexts/application.context";
import { AppService } from "../../../services/app.service";
import CustomTabPanel from "../../molecules/CustomTabPanel/CustomTabPanel";

const CreateForm = () => {
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [formName, setFormName] = useState<string>("");
    const [
        { data: getData, loading, error },
        createFormData
      ] = AppService.axiosHooks(
        {
          url: "forms",
          method: "POST"
        },
        { manual: true }
      );
    function a11yProps(index: number) {
        return {
            id: `form-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue);
    };
    const handleFormSubmit = () => {
        console.log("form submit", formName);
        if(formName){
            createFormData({data: {name: formName, studyId: appContext?.metaData?.studyId}}).then((res) => {
                console.log(res);
                if(res?.data){
                    navigate(`/clinicalApp/forms/${res.data.id}`);
                }
            }).catch((e: any) => {
                console.log(e);
            })
        }
    }
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormName(e.target.value);
    }
    return (
        <Box
            sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 1000,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
            }}
            >
            <Box >
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Create Form</Typography>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs scrollButtons="auto" aria-label="scrollable auto tabs" value={selectedTabIndex} onChange={handleChange}>
                    <Tab label="Custom Form" {...a11yProps(0)}/>
                    <Tab label="Pull From DB" {...a11yProps(1)}/>
                </Tabs>
            </Box>
            <CustomTabPanel value={selectedTabIndex} index={0}>
                <FormControl  style={{width: '100%', height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>
                    <TextField label="Form Name" style={{width: '50%'}} required={true} type="text" variant="outlined" value={formName} onChange={handleOnChange}/>
                    <Button style={{margin: "10px"}} onClick={handleFormSubmit} disabled={formName === ''}>Submit</Button>
                </FormControl>
            </CustomTabPanel>
            <CustomTabPanel value={selectedTabIndex} index={1}>
                <div style={{width: '100%', height: "200px", display: "flex", justifyContent:"center", alignItems:"center"}}>Pull from DB content </div>
            </CustomTabPanel>
        </Box>
    )
}
export default CreateForm;