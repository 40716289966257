import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router";

import {
  Grid,
  Paper,
  Box,
  Typography,
  Select,
  Button,
  Stack,
  TextField,
  MenuItem,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { ClearAllOutlined, SaveOutlined } from "@mui/icons-material";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import { AppService } from "../../../services/app.service";
import axios from "axios";
import Alert from "../../molecules/Alert/Alert";

interface IAccess {
  id: number;
  name: string;
}
interface IField {
  [key: string]: any;
  firstname: string;
  errfirstname: boolean;
  middlename: string;
  lastname: string;
  email: string;
  errEmail: string;
  phoneNumber: string;
  username: string;
  errUserName: boolean;
  password: string;
  errPassword: boolean;
  address: string;
  city: string;
  country: string;
  pincode: string;
  requestuser: string;
  acccessrights: string;
  status: string;
  website: string;
}

function OnboardingFrom() {
  const navigate = useNavigate();
  const location = useLocation();
  var name = "";
  var type = "";
  var path = "";
  var pathname = location.pathname;
  if (pathname.indexOf("/clinicalApp/onboarding-user/form") >= 0) {
    name = "User";
    type = "User";
    path = "/clinicalApp/onboarding-user";
  } else if (pathname.indexOf("/clinicalApp/onboarding-client/form") >= 0) {
    name = "Client";
    type = "Client";
    path = "/clinicalApp/onboarding-client";
  } else if (
    pathname.indexOf("/clinicalApp/onboarding-client-user/form") >= 0
  ) {
    name = "User";
    type = "Client User";
    path = "/clinicalApp/onboarding-client-user";
  } else if (pathname.indexOf("/clinicalApp/onboarding-sponser/form") >= 0) {
    name = "Sponsor";
    type = "Sponsor";
    path = "/clinicalApp/onboarding-sponser";
  }
  var breadCrumbList = [
    { title: "Home", link: "/clinicalApp/dashboard" },
    { title: "Onboarding", link: "" },
    { title: name, link: "" },
  ];
  let { formID } = useParams();
  const [openAlert, setopenAlert] = useState(false);
  const [openAlertMsg, setopenAlertMsg] = useState("");
  const [openAlertStatus, setopenAlertStatus] = useState("");

  const [fields, setFields] = useState<IField[]>([
    {
      firstname: "",
      errfirstname: false,
      middlename: "",
      lastname: "",
      email: "",
      errEmail: "",
      phoneNumber: "",
      username: "",
      errUserName: false,
      password: "",
      errPassword: false,
      address: "",
      city: "",
      country: "",
      pincode: "",
      requestuser: "",
      acccessrights: "",
      status: "Active",
      website: "",
    },
  ]);

  const [accessRights, setAccessRights] = useState<IAccess[]>([]);
  const [userList, setUserList] = useState([]);
  const [submitCheck, setSubmitCheck] = useState("No");
  const [btnloading, setBtnLoading] = useState(false);

  React.useEffect(() => {
    loadAccessMgtData();
    loadDataEntry();
    if (formID !== "" && typeof formID !== "undefined") {
      loadUserDetails();
    }
  }, []);

  const loadAccessMgtData = () => {
    AppService.axios
      .get("accessmanagements")
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setAccessRights(res.data);
          }
        }
      })
      .catch((e) => {
        console.log(`Error in AccessManagement - ${e}`);
      });
  };
  const loadUserDetails = () => {
    AppService.axios
      .get(`users/${formID}`)
      .then((res) => {
        if (res.status === 200) {
          var results = res.data;
          console.log(res.data, "res.data");
          var fieldsDetails = [
            {
              firstname: results.firstName,
              errfirstname: false,
              middlename: results.middlename !== null ? results.middlename : "",
              lastname: results.lastName !== null ? results.lastName : "",
              email: results.email,
              errEmail: "",
              phoneNumber:
                results.phoneNumber !== null ? results.phoneNumber : "",
              username: results.username,
              errUserName: false,
              password: results.password,
              errPassword: false,
              address: results.address !== null ? results.address : "",
              city: results.city !== null ? results.city : "",
              country: results.country !== null ? results.country : "",
              pincode: results.pincode !== null ? results.pincode : "",
              requestuser: "",
              acccessrights:
                results.authorities.length > 0
                  ? results.authorities[0].name
                  : "",
              status: results.status !== null ? results.status : "Active",
              website: results.website !== null ? results.website : "",
              userType: type,
            },
          ];
          setFields(fieldsDetails);
          setSubmitCheck("Yes");
          // setAccessRights(res.data);
        }
      })
      .catch((e) => {
        console.log(`Error in User Details - ${e}`);
      });
  };

  const loadDataEntry = () => {
    /* axios
      .get("http://localhost:3000/assets/onboardinguser.json")
      .then((res) => {
        setUserList(res.data);
      }); */
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    var value = event.target.value;
    var name = event.target.name;
    updateValue(name, value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    var value = event.target.value;
    var name = event.target.name;
    updateValue(name, value);
  };
  const updateValue = (name: string, value: string) => {
    var firstname = name === "firstname" ? value : fields[0].firstname;
    var errfirstname =
      name === "firstname"
        ? value === ""
          ? true
          : false
        : fields[0].errfirstname;
    var middlename = name === "middlename" ? value : fields[0].middlename;
    var lastname = name === "lastname" ? value : fields[0].lastname;
    var email = name === "email" ? value : fields[0].email;
    var errEmail =
      name === "email"
        ? value === ""
          ? "This field is required"
          : ""
        : fields[0].errEmail;

    var phoneNumber = name === "phoneNumber" ? value : fields[0].phoneNumber;
    var username = name === "username" ? value : fields[0].username;
    var errUserName =
      name === "username"
        ? value === ""
          ? true
          : false
        : fields[0].errUserName;

    var password = name === "password" ? value : fields[0].password;
    var errPassword =
      name === "password"
        ? value === ""
          ? true
          : false
        : fields[0].errPassword;

    var address = name === "address" ? value : fields[0].address;
    var city = name === "city" ? value : fields[0].city;
    var country = name === "country" ? value : fields[0].country;
    var pincode = name === "pincode" ? value : fields[0].pincode;
    var requestuser = name === "requestuser" ? value : fields[0].requestuser;
    var acccessrights =
      name === "acccessrights" ? value : fields[0].acccessrights;
    var website = name === "website" ? value : fields[0].website;

    var fieldsDetails = [
      {
        firstname: firstname,
        errfirstname: errfirstname,
        middlename: middlename,
        lastname: lastname,
        email: email,
        errEmail: errEmail,
        phoneNumber: phoneNumber,
        username: username,
        errUserName: errUserName,
        password: password,
        errPassword: errPassword,
        address: address,
        city: city,
        country: country,
        pincode: pincode,
        requestuser: requestuser,
        acccessrights: acccessrights,
        status: fields[0].status,
        userType: type,
        website: website,
      },
    ];
    setFields(fieldsDetails);
    setSubmitCheck("No");
  };

  const saveChanges = () => {
    console.log(fields, "fieldsfields");
    var fieldsList = fields;
    var error = 0;
    if (fieldsList[0].firstname === "") {
      error++;
      fieldsList[0].errfirstname = true;
    }
    if (fieldsList[0].email === "") {
      error++;
      fieldsList[0].errEmail = "This field is required";
    } else {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!fieldsList[0].email.match(validRegex)) {
        error++;
        fieldsList[0].errEmail = "Invalid email address!";
      }
    }
    if (fieldsList[0].username === "") {
      error++;
      fieldsList[0].errUserName = true;
    }
    if (fieldsList[0].password === "") {
      error++;
      fieldsList[0].errPassword = true;
    }

    setFields(fieldsList);
    setSubmitCheck("Yes");
    if (error === 0) {
      const postData = {
        username: fieldsList[0].username,
        password: fieldsList[0].password,
        firstName: fieldsList[0].firstname,
        middlename: fieldsList[0].middlename,
        lastName: fieldsList[0].lastname,
        image: "",
        phoneNumber: fieldsList[0].phoneNumber,
        address: fieldsList[0].address,
        city: fieldsList[0].city,
        country: fieldsList[0].country,
        pinCode: fieldsList[0].pincode,
        email: fieldsList[0].email,
        website: fieldsList[0].website,
        status: "Active",
        userType: type,
        authorities: [
          {
            name: fieldsList[0].acccessrights,
          },
        ],
      };
      if (formID !== "" && typeof formID !== "undefined") {
        AppService.axios
          .put(`users/${formID}`, postData)
          .then((res) => {
            if (res.status === 200) {
              setopenAlert(true);
              setopenAlertStatus("success");
              setopenAlertMsg("User Update Success");
              //navigate("/clinicalApp/onboarding");
            } else {
              setopenAlert(true);
              setopenAlertStatus("error");
              setopenAlertMsg("User Not Update");
            }
            AutoCloseAlert();
            setBtnLoading(false);
          })
          .catch((e) => {
            setopenAlert(true);
            setopenAlertStatus("error");
            setopenAlertMsg("User Not Update");
            AutoCloseAlert();
            console.log(`Error in Login - ${e}`);
          });
      } else {
        AppService.axios
          .post(`users`, postData)
          .then((res) => {
            if (res.status === 200) {
              setopenAlert(true);
              setopenAlertStatus("success");
              setopenAlertMsg("User Created Success");
              setTimeout(() => {
                navigate(path);
              }, 2000);
            } else {
              setopenAlert(true);
              setopenAlertStatus("error");
              setopenAlertMsg("User Not create");
            }
            AutoCloseAlert();
            setBtnLoading(false);
          })
          .catch((e) => {
            setopenAlert(true);
            setopenAlertStatus("error");
            setopenAlertMsg("User Not create");
            AutoCloseAlert();
            console.log(`Error in Login - ${e}`);
          });
      }
    }
  };
  const handleCloseAlert = () => {
    setopenAlert(false);
    setopenAlertStatus("");
    setopenAlertMsg("");
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      setopenAlert(false);
      setopenAlertStatus("");
      setopenAlertMsg("");
    }, 5000);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Grid container>
          <Breadcrumb breadCrumbList={breadCrumbList} />
          <Paper sx={{ width: 1, padding: 5 }}>
            <Typography variant="h6" gutterBottom>
              {formID !== "" && typeof formID !== "undefined" ? "Edit" : "Add"}{" "}
              {name}
            </Typography>
            <Grid item xs={12}>
              <TextField
                label="First Name*"
                variant="standard"
                name="firstname"
                sx={{ marginRight: "20px" }}
                value={fields[0].firstname}
                onChange={handleTextFieldChange}
                error={fields[0].errfirstname}
                helperText={
                  fields[0].errfirstname === true && "This field is required"
                }
              />
              <TextField
                label="Middle Name"
                variant="standard"
                name="middlename"
                sx={{ marginRight: "20px" }}
                value={fields[0].middlename}
                onChange={handleTextFieldChange}
              />
              <TextField
                label="Last Name"
                variant="standard"
                name="lastname"
                sx={{ marginRight: "20px" }}
                value={fields[0].lastname}
                onChange={handleTextFieldChange}
              />
              <TextField
                label="Email*"
                name="email"
                value={fields[0].email}
                onChange={handleTextFieldChange}
                variant="standard"
                sx={{ marginRight: "20px" }}
                error={fields[0].errEmail !== "" ? true : false}
                helperText={fields[0].errEmail}
              />
              <TextField
                label="Phone"
                name="phoneNumber"
                value={fields[0].phoneNumber}
                onChange={handleTextFieldChange}
                variant="standard"
                sx={{ marginRight: "20px" }}
              />
              {type === "Sponsor" && (
                <TextField
                  label="Website"
                  name="website"
                  value={fields[0].website}
                  onChange={handleTextFieldChange}
                  variant="standard"
                  sx={{ marginRight: "20px" }}
                />
              )}
            </Grid>
            <Typography variant="h6" gutterBottom mt={3}></Typography>
            <Grid item xs={12}>
              <TextField
                label="User Name*"
                variant="standard"
                name="username"
                sx={{ marginRight: "20px" }}
                value={fields[0].username}
                onChange={handleTextFieldChange}
                error={fields[0].errUserName}
                helperText={fields[0].errUserName && "This field is required"}
              />
              <TextField
                label="Password*"
                name="password"
                value={fields[0].password}
                onChange={handleTextFieldChange}
                variant="standard"
                sx={{ marginRight: "20px" }}
                error={fields[0].errPassword}
                helperText={fields[0].errPassword && "This field is required"}
                type="password"
              />
            </Grid>
            <Typography variant="h6" gutterBottom mt={3}></Typography>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="standard"
                name="address"
                sx={{ marginRight: "20px" }}
                value={fields[0].address}
                onChange={handleTextFieldChange}
              />
              <TextField
                label="City"
                variant="standard"
                name="city"
                sx={{ marginRight: "20px" }}
                value={fields[0].city}
                onChange={handleTextFieldChange}
              />
              <TextField
                label="Country"
                name="country"
                sx={{ marginRight: "20px" }}
                value={fields[0].country}
                onChange={handleTextFieldChange}
                variant="standard"
              />
              <TextField
                label="Pincode"
                name="pincode"
                value={fields[0].pincode}
                onChange={handleTextFieldChange}
                variant="standard"
              />
            </Grid>
            {
              /* (formID === "" || typeof formID === undefined) && ( */
              <>
                {/* <Typography variant="h6" gutterBottom mt={3}>
                  Send Creation Request
                </Typography>
                <Grid item xs={8}>
                  <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                    <InputLabel id="demo-select-small-label">User</InputLabel>
                    <Select
                      label="User"
                      name="requestuser"
                      onChange={handleChange}
                    >
                      {userList.length > 0 &&
                        userList.map((option: any) => (
                          <MenuItem value={option.id}>{option.name}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid> */}
              </>
              /* ) */
            }
            <Typography variant="h6" gutterBottom mt={3}>
              Authorities
            </Typography>
            <Grid item xs={8}>
              <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
                <InputLabel id="demo-select-small-label">
                  Select Authorities
                </InputLabel>
                <Select
                  label="Select Authorities"
                  name="acccessrights"
                  onChange={handleChange}
                  value={fields[0].acccessrights}
                >
                  {accessRights.length > 0 &&
                    accessRights.map((option: any, index: number) => (
                      <MenuItem value={option.name} key={index}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Box>
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="right"
                spacing={2}
                pt={4}
                pb={2}
              >
                <Link to={path}>
                  <Button variant="outlined" endIcon={<ClearAllOutlined />}>
                    Cancel
                  </Button>
                </Link>
                <LoadingButton
                  onClick={saveChanges}
                  loading={btnloading}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                  variant="contained"
                >
                  {formID !== "" && typeof formID !== "undefined"
                    ? "Update"
                    : "Add"}{" "}
                  {name}
                </LoadingButton>
              </Stack>
            </Box>
          </Paper>
        </Grid>
      </Box>
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </Box>
  );
}
export default OnboardingFrom;
