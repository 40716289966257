import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "./FormDatePicker.css";
export interface IFormDatePickerProps {
  id: string | undefined;
  label: string;
  value?: string;
  disabled?: boolean;
}
const FormDatePicker = (props: IFormDatePickerProps) => {
  const { id, label, value, disabled } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker value={value} disabled={disabled} label={label} className="datePicker"/>
    </LocalizationProvider>
  );
};
export default FormDatePicker;
