import React, { useState, useRef, useContext } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Box,
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import Breadcrumb from "../../organisms/Breadcrumb/Breadcrumb";
import Dialog from "../../molecules/Dialog/Dialog";
import Alert from "../../molecules/Alert/Alert";
import { AppService } from "../../../services/app.service";
import AppContext from "../../../contexts/application.context";
interface userType {
  typeOfUser: string;
}
const OnboardingGrid = (props: userType) => {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  console.log(context, "context.isLoggedIn");
  const location = useLocation();
  console.log(location, "locationlocationlocation");
  var name = "";
  var type = "";
  var path = "";
  if (props.typeOfUser === "User") {
    name = "User";
    type = "User";
    path = "clinicalApp/onboarding-user";
  } else if (props.typeOfUser === "Client") {
    name = "Client";
    type = "Client";
    path = "clinicalApp/onboarding-client";
  } else if (props.typeOfUser === "Client User") {
    name = "User";
    type = "Client user";
    path = "clinicalApp/onboarding-client-user";
  } else if (props.typeOfUser === "Sponsor") {
    name = "Sponsor";
    type = "sponsor";
    path = "clinicalApp/onboarding-sponsor";
  }
  var breadCrumbList = [
    { title: "Home", link: "/clinicalApp/dashboard" },
    { title: "Onboarding", link: "" },
    { title: name, link: "" },
  ];

  const [gridLoading, setGridLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [openDialog, setopenDialog] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [openAlertMsg, setopenAlertMsg] = useState("");
  const [openAlertStatus, setopenAlertStatus] = useState("");
  const editID = useRef(0);

  React.useEffect(() => {
    loadUser();
  }, []);

  const loadUser = () => {
    AppService.axios
      .get("users?userType=" + type)
      .then((res) => {
        if (res.status === 200) {
          setGridLoading(false);
          if (res.data.length > 0) {
            setUserList(res.data);
          }
        }
      })
      .catch((e) => {
        setGridLoading(false);
        console.log(`Error in user list - ${e}`);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    params: GridRenderCellParams
  ) => {
    editID.current = params.row.id;
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = (params: GridRenderCellParams) => {
    navigate(`/${path}/form/${editID.current}/`);
  };
  const handleRemove = (params: any) => {
    setopenDialog(true);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDialog = () => {
    setopenDialog(false);
  };
  const handleAcceptDialog = () => {
    AppService.axios
      .delete(`users/${editID.current}`)
      .then((res) => {
        if (res.status === 200) {
          loadUser();
          setopenDialog(false);
          setopenAlert(true);
          setopenAlertStatus("success");
          setopenAlertMsg("User Deleted Success");
        }
      })
      .catch((e) => {
        setGridLoading(false);
        setopenAlert(true);
        setopenAlertStatus("error");
        setopenAlertMsg("User Not delete");
        console.log(`Error in remove user - ${e}`);
      });
  };

  const handleCloseAlert = () => {
    setopenAlert(false);
    setopenAlertStatus("");
    setopenAlertMsg("");
    AutoCloseAlert();
  };
  const AutoCloseAlert = () => {
    setTimeout(() => {
      setopenAlert(false);
      setopenAlertStatus("");
      setopenAlertMsg("");
    }, 5000);
  };

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "User Name",
      width: 200,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      editable: false,
    },
    {
      field: "authorities",
      headerName: "Authorities",
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        console.log(params, "paramsparamsparams");
        return params.value.map((item: any) => {
          return item.name;
        });
      },
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 120,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      editable: false,
    },

    {
      field: "status",
      headerName: "Status",
      width: 100,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 30,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, params)}
            >
              <MoreIcon />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <a href={void 0} onClick={(event) => handleEdit(params)}>
                <MenuItem>Edit</MenuItem>
              </a>

              <MenuItem onClick={(event) => handleRemove(params)}>
                Remove
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} lg={9}>
        <Breadcrumb breadCrumbList={breadCrumbList} />
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              component="h3"
              variant="subtitle1"
              color="primary"
              gutterBottom
            >
              {name}
            </Typography>
          </Box>
          <Box
            mb={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Link to={`${location.pathname}/form`}>
              <Button id="basic-button" variant="contained">
                Add {name}
              </Button>
            </Link>
          </Box>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={userList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
              disableColumnSelector
              loading={gridLoading}
              localeText={{
                noRowsLabel:
                  gridLoading === false && userList.length === 0
                    ? `No ${name} Found`
                    : "",
              }}
            />
          </div>
        </Paper>
      </Grid>
      <Dialog
        openDialog={openDialog}
        dialogMsg={"Are you sure want remove this " + name + "?"}
        dialogHeader={"Alret"}
        handleCloseDialog={handleCloseDialog}
        handleAcceptDialog={handleAcceptDialog}
      />
      <Alert
        openAlertStatus={openAlertStatus}
        openAlert={openAlert}
        openAlertMsg={openAlertMsg}
        handleCloseAlert={handleCloseAlert}
      />
    </React.Fragment>
  );
};

export default OnboardingGrid;
