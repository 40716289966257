import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import {
  Box,
  Chip,
  Grid,
  Paper,
  Menu,
  MenuItem,
  Button,
  Typography,
  InputLabel,
  Input,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Filters from "../../organisms/Filters/Filters";
import axios from "axios";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import SearchIcon from "@mui/icons-material/Search";
import { AppSMService } from "../../../services/app.service";
interface userType {
  isDataEntryReviewer: boolean;
}
const DataEntryGrid = (props: userType) => {
  const navigate = useNavigate();
  const [reject, setReject] = useState(0);
  const [fomrList, setFormList] = useState([]);
  const [filterList, setFilterList] = useState(
    // 20231012200832
    // http://localhost:3000/assets/filterlist.json

    [
      {
        value: "",
        options: [],
        type: "auctocomplte",
        id: "client",
        label: "Cleint Name",
      },
      {
        value: "",
        options: [],
        type: "auctocomplte",
        id: "project",
        label: "Project Name",
      },
      {
        value: "",
        options: [],
        type: "auctocomplte",
        id: "study",
        label: "Study Name",
      },
      {
        value: "",
        options: [],
        type: "auctocomplte",
        id: "site",
        label: "Site Name",
      },
      {
        value: "",
        options: [],
        type: "auctocomplte",
        id: "subject",
        label: "Subject Name",
      },
      {
        value: "",
        options: [],
        type: "auctocomplte",
        id: "visit",
        label: "Visit Name",
      },
    ]
  );

  React.useEffect(() => {
    loadDataEntry();
    loadclient();
  }, []);

  const loadDataEntry = () => {
    axios.get("http://localhost:3000/assets/formlist.json").then((res) => {
      console.log(res.data, "res.datares.data");
      setFormList(res.data);
    });
  };
  const loadclient = () => {
    AppSMService.axios.get("study/clients").then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          updatefliter(res.data, "client", "");
        }
      }
    });
  };
  const loadProject = (value: any) => {
    AppSMService.axios.get(`study/projects?clientId=${value}`).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          updatefliter(res.data, "project", "");
        }
      }
    });
  };
  const loadStudy = (value: any) => {
    AppSMService.axios.get(`study/studies?projectId=${value}`).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          updatefliter(res.data, "study", "");
        }
      }
    });
  };
  const loadSite = (value: any) => {
    AppSMService.axios.get(`study/sites?studyId=${value}`).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          updatefliter(res.data, "site", "");
        }
      }
    });
  };
  const loadSubject = (value: any) => {
    AppSMService.axios.get(`study/subjects?siteId=${value}`).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          updatefliter(res.data, "subject", "");
        }
      }
    });
  };
  const loadVisit = (value: any) => {
    AppSMService.axios.get(`study/visits?subjectId=${value}`).then((res) => {
      if (res.status === 200) {
        if (res.data.length > 0) {
          updatefliter(res.data, "visit", "");
        }
      }
    });
  };

  const setInputValue = (value: any, type: any) => {
    updatefliter("", type, value);
    if (type === "client") {
      loadProject(value);
    } else if (type === "project") {
      loadStudy(value);
    } else if (type === "study") {
      loadSite(value);
    } else if (type === "site") {
      loadSubject(value);
    } else if (type === "subject") {
      loadVisit(value);
    }
  };
  const updatefliter = (dataList: any, type: any, setValue: any) => {
    var updatefilterList = new Array();
    filterList.map((item) => {
      if (item.id === type) {
        var values = item;
        if (setValue !== "") {
          values.value = setValue;
        } else {
          values.options = dataList;
        }

        updatefilterList.push(values);
      } else {
        updatefilterList.push(item);
      }
    });
    console.log(updatefilterList, "updatefilterList");
    setFilterList(updatefilterList);
  };
  const applyFilter = () => {
    var studyId,
      siteId,
      subjectId,
      visitId = "";
    filterList.map((item) => {
      if (item.id === "study") {
        studyId = item.value;
      } else if (item.id === "site") {
        siteId = item.value;
      } else if (item.id === "subject") {
        subjectId = item.value;
      } else if (item.id === "visit") {
        visitId = item.value;
      }
    });
    AppSMService.axios
      .get(
        `de-forms?studyId=${studyId}&siteId=${siteId}&subjectId=${subjectId}&visitId=${visitId}`
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data, "res.datares.data");
          if (res.data.length > 0) {
            //updatefliter(res.data, "visit", "");
          }
        }
      });
  };
  const handleEvent = () => {
    console.log(props.isDataEntryReviewer, "props.isDataEntryReviewer");
    if (props.isDataEntryReviewer) {
      navigate("/clinicalApp/dataEntryReviewer/form");
    } else {
      navigate("/clinicalApp/dataEntry/form");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  /*  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }; */
  const handleClose = () => {
    setAnchorEl(null);
  };

  type IColumn = {
    id: string;
    label: string;
    minWidth: number;
  };
  type IRows = {
    id: number;
    formId: string;
    status: string;
    visitdate: string;
    previousvisit: string;
    location: string;
    registered: string;
  };
  interface ICusomTable {
    columns: IColumn[];
    row: IRows;
  }
  const columns: GridColDef[] = [
    {
      field: "formId",
      headerName: "Form Id",
      width: 210,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 250,
      renderCell: (params: GridRenderCellParams): React.ReactNode => {
        return <FormStatus status={params.value ?? ""} />;
      },
    },
    {
      field: "visitdate",
      headerName: "Visit Time",
      width: 250,
      editable: false,
    },
    {
      field: "previousvisit",
      headerName: "Previous Visit",
      width: 210,
      editable: false,
    },
    {
      field: "location",
      headerName: "Location",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 200,
      /* valueGetter: (params) =>
              `${params.row.firstName || ''} ${params.row.lastName || ''}`, */
    },
    {
      field: "registered",
      headerName: "Registered",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      /* valueGetter: (params) =>
              `${params.row.firstName || ''} ${params.row.lastName || ''}`, */
    },
  ];
  console.log(fomrList, "fomrListfomrList");

  const rowsUpdated = fomrList;

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const id = query.get("reject");
    // setReject(id);
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const [openSignoff, setOpenSignoff] = React.useState(false);
  const handleOpenSignoff = () => {
    setOpenSignoff(true);
    setAnchorEl(null);
  };
  const handleCloseSignoff = () => setOpenSignoff(false);
  var fileterLabel = (
    <>
      <SearchIcon fontSize="small" /> Search
    </>
  );
  return (
    <React.Fragment>
      <Grid item xs={12} md={8} mb={4} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 240,
          }}
        >
          <Filters
            filterlist={filterList}
            buttonlabel={fileterLabel}
            setInputValue={setInputValue}
            applyFilter={applyFilter}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Typography
              component="h3"
              variant="subtitle1"
              color="primary"
              gutterBottom
            >
              Data Entry - Forms
            </Typography>
          </Box>
          <Box
            m={1}
            //margin
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant="contained"
            >
              Action
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link href="/clinicalApp/dataEntry/extractdata">
                <MenuItem>Extract Data</MenuItem>
              </Link>
              {props.isDataEntryReviewer === true && (
                <MenuItem onClick={handleOpenSignoff}>Sign Off</MenuItem>
              )}
            </Menu>
          </Box>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rowsUpdated}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              disableRowSelectionOnClick
              onRowClick={handleEvent}
            />
          </div>
        </Paper>
      </Grid>
      <Modal
        open={openSignoff}
        onClose={handleCloseSignoff}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Sign Off
                </Typography>
              </Toolbar>
            </AppBar>
          </Box>
          <Grid item xs={12} pt={5} pb={2} pl={4} pr={4}>
            <InputLabel htmlFor="input-with-icon-adornment">
              Comments
            </InputLabel>
            <Input
              style={{
                width: "100%",
              }}
              type="text"
            />
          </Grid>
          <Grid item xs={12} pb={20} pl={4} pr={4}>
            <InputLabel htmlFor="input-with-icon-adornment">
              Password
            </InputLabel>
            <Input
              style={{
                width: "100%",
              }}
              type="text"
            />
          </Grid>
          <Stack
            direction="row"
            alignItems="right"
            justifyContent="right"
            spacing={2}
            pt={4}
            pb={2}
            pr={4}
          >
            <Button variant="outlined" onClick={handleCloseSignoff}>
              Cancel
            </Button>
            <Button variant="contained">Sign Off</Button>
          </Stack>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default DataEntryGrid;
